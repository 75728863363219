<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    
  </div>
  
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'
import { watch } from '@vue/composition-api'
import { provideToast } from 'vue-toastification/composition'

import { useCssVar, useWindowSize } from '@vueuse/core'

import jwt from "@/http/requests/auth/jwt/index.js"
import store from '@/store'
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  methods: {
    StartLoading() {
      this.$vs.loading();
    },
     ConvertDate(date) {
  var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return date.getDate() + ' ' + ms[date.getMonth()] + ' ' + date.getFullYear();
},
    CloseLoading() {
      this.$vs.loading.close();
    },
    /* eslint-disable */
    checkAndAttachMapScriptGoogleMaps(callback) {
      if (!!window.google) {
        // If you're using vue cli, then directly checking
        // 'google' obj will throw an error at the time of transpiling.
        callback();
        return true;
      }
      window.mapApiInitialized = callback;
      let script = document.createElement("script");
      let language = this.$i18n.locale;
      let region = JSON.parse(
        localStorage.getItem("userInfo")
      ).countrycode.toUpperCase();
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFu68ylJSETtU0lF9sWIj3bh524ntyImQ&hl=ar&libraries=places,geometry&language=${language}
      &region=${region}&callback=mapApiInitialized`;
      script.defer = true;
      script.async = true;
      document.body.appendChild(script);
    },

    checkAndAttachMapScript() {
      let initialized = !!window.google;
      return new Promise(async (resolve, reject) => {
        if (initialized) {
          return resolve(window.google);
        }

        initialized = true;
        window.mapApiInitialized = () => resolve(window.google);
        let language = await this.$i18n.locale;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFu68ylJSETtU0lF9sWIj3bh524ntyImQ&libraries=places,geometry&language=${language}&callback=mapApiInitialized`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          resolve(window.google);
        };
        // script.onerror = () => reject(new Error("Script load error: " + src));
        document.body.appendChild(script);
      });
    },
    /* eslint-enable */
    showSuccess(message) {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: message
      });
    },
    showError(message) {
      if(!message)
        message="Error please try again";
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("Error"),
        text: message
      });
    },
    showAddSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("CreatedSuccessfully")
      });
    },
     noDocumentsUploaded() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("warning"),
        text: this.$t("noDocumentsUploaded")
      });
    },
     sessionBlock() {
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("SessionBlocked"),
        text: this.$t("SessionBlockMessage")
      });
    },
     sessionOldSelected() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("SessionSelected"),
        text: this.$t("sessionOldSelected")
      });
    },
      PastDate() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("PastDate"),
        text: this.$t("PastDateAlert")
      });
    },
    showUpdateSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("UpdatedSuccessfully")
      });
    },
    showAddFailed(err) {
      if (err != "") {
        this.$vs.notify({
          color: "success",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err
        });
      } else {
        this.$vs.notify({
          color: "success",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("CreatedFailed")
        });
      }
    },
    showUpdateFailed(err) {
      if (err != "") {
        console.log(err);
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err
        });
      } else {
        console.log(err);
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("UpdatedFailed")
        });
      }
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("DeletedSuccessfully")
      });
    },
    showDeleteFailed(err) {
      if (err != "") {
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err
        });
      } else {
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("DeleteFailed")
        });
      }
    },
    showFailed() {
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("Error"),
        text: this.$t("ErrorTryLater")
      });
    },

    showFillData() {
      this.$vs.notify({
        color: "danger",
        position: "right-bottom",
        text: "Please fill all required data..."
      });
    },

    showSuccessCreate() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Create"),
        text: this.$t("RegisterCreatedSuccessfuly")
      });
    },
    showAlreadyExist() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("AlreadyExist"),
        text: this.$t("ThisItemAlreadyExist")
      });
    },
    showAddDriverToBiadjoSuccess() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("AssignToDriver"),
        text: this.$t("Assign")
      });
    },
    showSuccess() {
      this.$vs.notify({
        color: "success",
        position: "top-center",
        title: this.$t("Update"),
        text: this.$t("showUpdate")
      });
    },
    showSuccessAddNote() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Update"),
        text: this.$t("SuccessAddNote")
      });
    },
    // showDriverAddeddSuccessfuly() {
    //   this.$vs.notify({
    //     color: "primary",
    //     position: "top-center",
    //     title: this.$t("Success"),
    //     text: this.$t("DriverAddeddSuccessfuly")
    //   });
    // },
    showNoRecords() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("Search"),
        text: this.$t("showNoDataAvailable")
      });
    },
    showCheckEmail() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("ResetPassword"),
        text: this.$t("showCheckEmail")
      });
    },
    showPasswordChanged() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("ResetPassword"),
        text: this.$t("showPasswordChanged")
      });
    },
    notAllowedToAccessCurrentPage(CurrentStatus) {
      if (
        CurrentStatus == "BS11" ||
        CurrentStatus == "BS13" ||
        CurrentStatus == "BS14" ||
        CurrentStatus == "BS15" ||
        CurrentStatus == "BS16" ||
        CurrentStatus == "SO3" ||
        CurrentStatus == "SO4" ||
        CurrentStatus == "CS2" ||
        CurrentStatus == "CS3" ||
        CurrentStatus == "PS1" ||
        CurrentStatus == "DPS1"
      ) {
        this.$router.go(-1);
      }
    },
    InvalidImageType() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("InvalidType"),
        text: this.$t("InvalidImageType")
      });
    },
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark")) {
          document.body.classList.remove("theme-semi-dark");
        }
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark")) {
          document.body.classList.remove("theme-dark");
        }
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark")) {
          document.body.classList.remove("theme-dark");
        }
        if (document.body.className.match("theme-semi-dark")) {
          document.body.classList.remove("theme-semi-dark");
        }
      }
    },
    handleWindowResize() {
       var sliderDev=document.getElementById("sliderImage");
       var searchDev =document.getElementById("searchDev");
       if(sliderDev&&searchDev)
          searchDev.style.top = sliderDev.clientHeight-(searchDev.clientHeight+20)+'px'

          this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  async created() {
    
 jwt.init(this);

  
 var vm=this;
 window.addEventListener("focus", function(){
if(vm.activeUserInfo.uid){
  vm.$store.dispatch("fetchNotifications");
}

});

 window.showAddSuccess = this.showAddSuccess;
    window.noDocumentsUploaded = this.noDocumentsUploaded;
    window.showUpdateSuccess = this.showUpdateSuccess;
     window.sessionBlock = this.sessionBlock;
    window.sessionOldSelected = this.sessionOldSelected;
    window.PastDate = this.PastDate;
    window.showAddFailed = this.showAddFailed;
    window.showUpdateFailed = this.showUpdateFailed;
    window.showDeleteSuccess = this.showDeleteSuccess;
    window.showDeleteFailed = this.showDeleteFailed;
    window.showFailed = this.showFailed;
    window.StartLoading = this.StartLoading;
    window.CloseLoading = this.CloseLoading;
    window.showError = this.showError;
     window.showSuccess = this.showSuccess;
    window.InvalidImageType = this.InvalidImageType;
    window.showFillData = this.showFillData;
    window.showSuccess = this.showSuccess;
    window.showAddDriverToBiadjoSuccess = this.showAddDriverToBiadjoSuccess;
    window.showNoRecords = this.showNoRecords;
    window.showCheckEmail = this.showCheckEmail;
    window.showPasswordChanged = this.showPasswordChanged;
    window.notAllowedToAccessCurrentPage = this.notAllowedToAccessCurrentPage;
    window.showSuccessCreate = this.showSuccessCreate;
    window.showAlreadyExist = this.showAlreadyExist;
    window.checkAndAttachMapScript = this.checkAndAttachMapScript;
    window.showDriverAddeddSuccessfuly = this.showDriverAddeddSuccessfuly;
    window.showSuccessAddNote = this.showSuccessAddNote;
    window.checkAndAttachMapScriptGoogleMaps = this.checkAndAttachMapScriptGoogleMaps;


  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark','darkBlue']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }
debugger
    // Set RTL
    debugger
    if( localStorage.getItem("appRTL")!=null&&localStorage.getItem("appRTL")!=undefined){
      var bollIsRTL = localStorage.getItem("appRTL")=="true"? true : false
      this.$vs.rtl= bollIsRTL;
    document.documentElement.setAttribute('dir', bollIsRTL ? 'rtl' : 'ltr')
    if(localStorage.getItem("SaveLang")!=null&&localStorage.getItem("SaveLang")!=undefined)
     this.$i18n.locale = localStorage.getItem("SaveLang")
     else{
     this.$i18n.locale = 'en'

     }
    }
    else{
      const { isRTL } = $themeConfig.layout
      this.$vs.rtl= isRTL;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    if(localStorage.getItem("SaveLang")!=null&&localStorage.getItem("SaveLang")!=undefined)
     this.$i18n.locale = localStorage.getItem("SaveLang")
     else{
     this.$i18n.locale = 'en'

     }
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  watch: {
   
  },
}
</script>
<style>
@font-face {
  font-family: "Bahij Janna-Regular";
  src: local("Bahij Janna-Regular"),
   url(./assets/font/Bahij-Janna-Bold.ttf) format("truetype");
}
</style>