export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      breadcrumb: [

      ],
      pageTitle: "",
      rule: "patient",
      resource: 'Auth',
      redirectIfLoggedIn: true,
      show:true
  },
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    meta: {
      breadcrumb: [

      ],
      pageTitle: "",
      rule: "patientanonymous"
  },
    // rule:'',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
]
