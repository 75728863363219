// en, de, fr, pt
export default {
  en: {
    OnlineDoctor: "Online Doctor",
    BrowseAll: "Browse All",
    OurDoctor: "Our Doctor",
    MedicalSpecialties: "Medical Specialties",
    AlliedMedicalSpecialties: "Allied Medical Specialties",
    ISHaveParentDiseasesProblem: "Do any of your parents suffer from any chronic or cancerous diseases?",
    ISHaveBrothorsDiseasesProblem: "Do any of your parents suffer from any chronic or cancerous diseases?",
    IsBrother: "Brother, sister",
    BrothorsDiseasesName: "sibling disease name",
    ISHaveAnyDiseasesProblemNow: "Are you suffering from any diseases currently?",
    ReasoneDiseasesProblemNow: "current cause of disease",
    PeriodDiseasesProblemNow: "current illness duration",
    ParentDiseasesName: "Mention the relationship of kinship + type of disease.",
    ISHaveParentDiseasesProblem: "Do any of your parents suffer from any chronic or cancerous diseases?",
    ISHaveSensitiveProblem: "Do you have a drug allergy or food allergy?",
    PetName: "name of the animal",
    ISHavePet: "Do you raise an animal at home?",
    ISHaveHistoryHospitalization: "Do you have a prior history of hospitalization?",
    ReasonOfHistoryHospitalization: "Reason for hospitalization and rate of improvement",
    IsSurgeryBefore: "Have you ever had any kind of surgeries?",
    ISHaveAnyCancerType: "Do you suffer from any type of cancer?",
    ISHaveVisionProblem: "Do you have vision problems?",
    ISHaveRheumaticProblem: "Do you suffer from rheumatic diseases (such as arthritis,...)?",
    ISHaveBlodProblem: "Do you suffer from any blood diseases?",
    ISHaveReproductiveSystemProblem: "Do you suffer from diseases of the reproductive system?",
    ISHaveDigestiveProblem: "Do you suffer from diseases of the digestive system (such as Irritable Bowel Syndrome)?",
    ISHavePsychiatricDisturbances: "Do you suffer from mental disorders such as (such as depression, schizophrenia, bipolar)?",
    ISHaveInfectiousDiseaseBefore: "Have you ever been diagnosed with infectious diseases (such as viral hepatitis, AIDS, seasonal influenza, Corona...)?",
    ISHaveChestProblem: "Do you suffer from any diseases in the chest?",
    ISHaveNeurologicalProblem: "Do you suffer from any neurological diseases?",
    IsHaveNutritionalSupplements: "Do you use nutritional supplements, such as vitamins or medicinal herbs, whether continuously or from time to time?",
    CanAddAttMedicine: "You can add an image or a file in the name of the treatment",
    IsHaveMedications: "Do you use any kind of medical medication, whether for a specific disease or not?",
    NumberOfBloodTransfusion: "Number Of Blood Transfusion",
    ISHaveBloodTransfusion: "IS Have Blood Transfusion",
    NumberOfCigarettesPerDay: "Number Of Cigarettes Per Day",
    IsHaveChronicDiseases: "Do you suffer from chronic diseases (such as diabetes, pressure, hypothyroidism, etc.)",
    MentionIt: "Mention It",
    IdNumber: "Id Number",
    NameEN: "First Name EN",
    SecondNameEN: "Second Name EN",
    ThirdNameEN: "Third Name EN",
    FamilyNameEN: "Family Name EN",
    NameAR: "First Name AR",
    SecondNameAR: "Second Name AR",
    ThirdNameAR: "Third Name AR",
    FamilyNameAR: "Family Name AR",
    OurPackages2: "Enjoy many specially designed package systems",
    AddHospitalSubSpecialites: "Add Hospital Sub Specialites",
    ParentSpecialities: "Parent Specialities",
    AddHospitalSpecialities: "Add Hospital Specialities",
    DoctorsSelected: "Doctors Selected",
    AddTreatmentSurgery: "Add/Edit Treatment Surgery",
    HospitalSpecialty: "Hospital Specialty",
    ReservedSurgery: "Reserved Surgery",
    HospitalPayments: "Hospital payments",
    hospital: "hospital",
    HospitalSetting: "Hospital Setting",
    Settings: "Settings",
    SpecialtiesHome: "The group of specialties in the hospital",
    DoctorsHome: "Group of doctors inside the hospital",
    AddSpecialtiesHome: "Manage the hospital specialties",
    AddDoctorsHome: "Manage the hospital doctors",
    SurgeriesTreatmentsHospitalHome: "All hospital surgeries and treatments",
    PaymentStatusHome: "Know about payment statuses now",
    CompletedRegisterationData: "Completed Registeration Data",
    Licences: "Licences",
    MoreInfo: "More Info",
    YourPatient: "Your patients",
    DoctorYearSummaryAppointments: "Doctor Year Summary Appointments",
    DoctorWeeklyAppointments: "Doctor Weekly Appointments",
    SurgeriesTreatmentsHome: "You have surgeries that you are going to do",
    thisWeekOnlineAppointmentsHome: "Know your dates for this week",
    PendingTaskHome: "Here you will find all your pending tasks",
    editYourAvailabilityHome: "Easily adjust your appointments.",
    addYourAvailabilityHome: "If you haven't added any appointments before, do so now",
    OnlineAppointmentHome: "Make sure your future appointments are in your schedule",
    YourPatientsHome: "Here you will find all your patients..",
    RepeatMedicalOnlineConsultationsHome: "You can know your doctor's upcoming appointments to book your consultation with the same doctor",
    PrintYourPrescriptionHome: "You can refer to the medical prescriptions submitted by the doctor at any time and print them easily",
    RequestOnlineConsultationsHome: "Choose your favorite doctor in all specialties and all over the world and book your appointment with ease",
    YourOnlineConsultationsHome: "Here you will find all your future, canceled and expired appointments",
    PackagesHome: "Follow the details of the packages that have been subscribed and track their expiry time",
    SurgeriesTreatmentsHome: "Details of your reservations for surgeries and modification of their procedures",
    PendingPaymentsHome: "All pending appointments and surgeries must be paid to complete your appointments",
    PendingDocumentHome: "must upload all pending documents of appointments and surgeries",
    YourMedicalReportsHome: "All previously uploaded documents will be found here and you can download them again or delete them",
    UpdateMedicalHistoryHome: "Update your medical data so that your doctor can analyze your health condition well",
    ChooseAnotherDoctor: "Choose Another Doctor",
    SessionDetails: "Session Details",
    ChooseDoctor: "Choose Doctor",
    PleaseSelectOnADoctor: "Please select a doctor to be able to book",
    Surgeryprofile: "Surgery profile",
    PatientRepeatMedical: "Patient Repeat Medical",
    SaveChanges: "Save Changes",
    PatientPaymentPending: "Patient Payment Pending",
    Actions: "Actions",
    MainData: "Main Data",
    Remove: "Remove",
    welcInSign: "Please sign-in to your account and start the adventure",
    WelcometoDaaweny: "Welcome to Daaweny! 👋",
    or: "or",
    Alreadyhaveanaccount: "Already have an account?",
    Signininstead: "Sign in instead",
    FullName: "Full Name",
    HospitalInfoAR: "Hospital Info AR",
    HospitalInfoEn: "Hospital Info EN",
    PRIVACYPOLICY: "PRIVACY POLICY",
    COLLABORATEFotter: "COLLABORATE",
    PATIENTCAREFOOTER: "PATIENT CARE",
    DescriptionOfAttachment: "Description Of Attachment",
    LoginAttempt: "Login Attempt",
    Youarealreadyloggedin: "You are already logged in!",

    ProjectTitle: "DocLinia",
    Join: "Join",
    PatientName: "PatientName",
    YourAllPendingPayments: "Your All Pending Payments",
    has: "has",
    Or: "Or",
    AlDoctor: "Doctor",
    DoctorNotes: "Doctor Notes",
    Doctorsignature: "Doctor signature",
    Block: "Block",
    Documentdoesntdownloadyet: "document of the patient",
    AddNew: "Add New",
    Action: "Action",
    descripton: "descripton",
    SuccsessMessage: "successfully . this operation done ",
    NameAr: "Arabic Name",
    NameEn: "English Name",
    FamliName: "Famly Name",
    FirstName: "First Name",
    Next: "Next",
    UpCommingDates: "Upcoming online appointments",
    OldDates: "Completed online appointments",
    showNoDataAvailable: "No data available... Please try again",
    NoNotesAvailable: "No notes available",
    SorryYouAccountisNotActive: "Sorry..You account is not active",
    WelcomeMsg: "Welcome back, Please login to your account.",
    back: "Back",
    Update: "Update",
    showError: "Something went wrong... Please try again",
    showUpdate: "Record updated successfully...",
    showCheckEmail: "Kindly check you email for new password...",
    showPasswordChanged: "Password changed...",
    NameFr: "French Name",
    AddressAr: "Arabic Adress",
    AddressEn: "English Adress",
    AddressFr: "French Adress",
    NoteAr: "Arabic Note",
    NoteEn: "English Note",
    NoteFr: "French Note",
    AboutUsAr: "Arabic AboutUs",
    AboutUsEn: "English AboutUs",
    AboutUsFr: "French AboutUs",
    Logo: "Logo",
    SendVertificationCode: "Send Vertification Code",
    sendcode: "send code",
    Cancel: "Cancel",
    Delete: "Delete",
    PhoneNumber: "Phone Number",
    VertificationCode: "Vertification Code",
    Vertify: "Vertify",
    MainInfo: "Main Info",
    LoginInfo: "Login Info",
    Save: "Save",
    Email: "Email",
    Signup: "Sign up",
    signinasa: "sign in as a",
    Password: "Password",
    Bus: "Bus",
    BackToLogin: "Back To Login",
    Buses: "Buses",
    Attachment: "Attachment",
    Installments: "Installments",
    Installment: "Installment",
    Stage: "Stage",
    StageYear: "Stage Year",
    stagee: "stagee",
    Internationalinsurance: "International insurance",
    Insurancecompanyname: "Insurance company name:",
    companyname: "company name:",
    socialhistory: "social history",
    History: " history",
    Class: "Class",
    Age: "Age",
    Subjects: "Subjects",
    NewAttachment: "New Attachment",
    AddAttachment: "Add Attachment",
    Description: "Description",

    URL: "URL",
    File: "File",
    NewBus: "New Bus",
    AddBus: "Add Bus",
    ModelAr: "Arabic Model",
    ModelEn: "English Model",
    ChooseYear: "Choose Year",
    ModelFr: "French Model",
    IsActive: "Active",
    BankName: "Bank Name",
    BranchAddress: "Branch Address",
    AccountNumber: "Account Number",
    IBAN: "IBAN ",
    DocliniaBankTransferDetails: "Doclinia Bank Transfer Details",
    HospitalBankTransferDetails: "Hospital Bank Transfer Details",
    AddInstallment: "Add Installment",
    NewInstallment: "New Installment",
    InstallmentTypeEn: "Installment Type English NAme",
    PayDate: "Pay Date",
    IsPaid: "Paid",
    Routes: "Routes",
    Users: "Users",
    Students: "Students",
    Teachers: "Teachers",
    Drivers: "Drivers",
    StudentExam: "Student Exam",
    StudentExamAnswer: "Student Exam Answer",
    ExaminationManagement: "Examination Management",
    QuestionBank: "Question Bank",
    ExamRule: "Exam Rule",
    ExamGeneration: "Exam Generation",
    Setting: "Setting",
    Date: "Date",
    ClassSessionName: "Session",
    SubjectName: "Subject",
    FromDate: "From",
    ToDate: "To",
    ClassSession: "Session",
    ClassSchedule: "Class Schedule",
    ClassScheduleAddEdit: "Class Schedule Add/Edit",
    Status: "Status",
    SchoolInfo: "School Info",
    AssessmentType: "Assessment Type",
    BusSuperVisor: "Bus SuperVisor",
    Language: "Language",
    GradeType: "Grade Type",
    ExamType: "Exam Type",
    QuestionLevel: "Question Level",
    QuestionType: "Question Type",
    Stages: "Stages",
    StageYears: "Stage Years",
    InstallmentType: "Installment Type",
    Name: "Name",
    Reset: "Reset",
    male: "male",
    female: "female",
    UserWithSamePhoneNumberExist: "User With Same Phone Number Exist",
    PleaseInsertYour: "Please Insert Your",
    emailaddress: "email address",
    SomethingWentWrong: "Something Went Wrong",
    CodeIsNotExist: "Code Is Not Exist",
    Welcome: "Welcome",
    Modules: "Modules",
    Newlanguage: "New language",
    AddLanguage: "Add Language",
    NewModule: "New Module",
    AddModule: "Add Module",
    Module: "Module",
    Route: "Route",
    Details: "Details",
    AddRouteDetails: "Add Route Details",
    Longitude: "Longitude",
    Latitude: "Latitude",
    Region: "Region",
    NewRouteDetails: "New Route Details",
    Model: "Model",
    BusSupervisor: "Bus supervisor",
    Driver: "Driver",
    DriverAddEdit: "Driver Add/Edit",
    AddDriver: "Add Driver",
    NewDriver: "New Driver",
    Classes: "Classes",
    NewSubject: "New Subject",
    Subject: "Subject",
    Teacher: "Teacher",
    NewClass: "New Class",
    Heavy: "Heavy",
    viewsurgery: "view surgery",
    TeacherAddEdit: "Teacher Add/Edit",
    SubjectMaxScore: "Subject Max Score",
    AddSubject: "Add Subject",
    QuestionNum: "Number of questions",
    QuestionAr: "Arabic Question",
    QuestionEn: "English Question",
    QuestionFr: "French Question",
    NewQuestion: "New Question",
    Question: "Question",
    Answers: "Answers",
    AnswerAr: "Arabic Answer",
    AnswerEn: "Englisg Answer",
    AnswerFr: "French Answer",
    IsRight: "IsRight",
    Addanswer: "Add Answer",
    DescriptionAr: "Arabic Description",
    DescriptionEn: "English Description",
    DescriptionFr: "French Description",
    Download: "Download",
    Type: "Type",
    AddRule: "Add Rule",
    NewRule: "New Rule",
    RuleInfo: "Rule Info",
    ExamStartDate: "Start Date",
    Moderate: "Moderate",
    Rate: "Rate",
    Occasionaly: "Occasionaly",
    Exams: "Exams",
    TimeOfExamInMinutes: "Exam Time In Minutes",
    Generate: "Generate",
    OtherCompany: "Other Company",

    ExamMainInfo: "Exam Main Info",
    GenerateQuestions: "Generate Questions",
    Text: "Text",
    TrueFalse: "True/False",
    MultiChoose: "Multi Choose",
    TimeInMinute: "Time In Minute",
    Weight: "Weight",
    TypeAr: "Arabic Type",
    TypeEn: "English Type",
    TypeFr: "French Type",
    UPDATE: "Update",
    OnlineAppointmentConfirmed: "Your online appointment is confirmed.",
    OnlineAppointmentConfirmedMessage: " You will be receiving an email with the online call link.",
    OnlineAppointmentConfirmedDescribe: " Please describe the symptoms to the doctor:",
    OnlineAppointmentConfirmedRequest: "Please upload your medical reports, scans, blood analysis or any other documents related to appointment on your profile.",
    AppointmentPendingPayments: "Appointment Pending Payments",
    SurgeriesPendingPayments: " Surgeries Pending Payments",
    PatientSymptoms: "Patient Symptoms",
    Amount: "Amount",
    InstallmentDate: "Installment Date",
    DeadLine: "DeadLine",
    LevelAr: "Arabic Level",
    LevelEn: "English Level",
    LevelFr: "French Level",
    EventType: "Event Type",
    Level: "Level",
    Events: "Events",
    SchoolArchitecture: "School Architecture",
    UserType: "User Type",
    RelatedAccounts: "Related Accounts",
    UserManagment: "User Managment",
    SubjectManagment: "Subject Managment",
    StageYearSubjects: "Stage Year Subjects",
    SubjectModules: "Subject Modules",
    BuseManagment: "Bus Managment",
    EventManagment: "Event Managment",
    InstallmentManagment: "Installment Managment",
    Grade: "Grade",
    Active: "Active",
    Inactive: "Inactive",
    AllAddedTreatments: "All Added Treatments",
    AllAddedDoctors: "All Added Doctors",
    YourAllAppointmentConsulataion: "Your booked consultations appointments ",
    YourAllSurgeriesAndTreatment: "Your Booked Surgeries And Treatments",
    True: "True",
    yes: "yes",
    no: "no",
    False: "False",
    CustomUser: "Custom User",
    AllDriver: "All Driver",
    AllTeacher: "All Teacher",
    AllParent: "All Parent",
    StudentTeacherInstallments: "Student/Teacher Installments",
    User: "User",
    Success: "Success",
    CreatedSuccessfully: "Created Successfully",
    CreateAccount: "Created Account",
    UpdatedSuccessfully: "Updated Successfully",
    Error: "Error",
    CreatedFailed: "Created Failed",
    UpdatedFailed: "Updated Failed",
    DeletedSuccessfully: "Deleted Successfully",
    DeleteFailed: "Delete Failed",
    Confirm: "Confirm",
    Confirmpassword: "Confirm password",
    DeleteConfirmText: "Are you sure you want delete this record?",
    ErrorTryLater: "SomeThing went wrong , please try later ..",
    Dashboard: "Dashboard",
    Dashboards: "Dashboards",
    eCommerce: "eCommerce",
    Apps: "Apps",
    Todo: "Todo",
    hasReservationSessionon: "has Reservation Session on",
    hasReservationSurgeries: "has Reservation Surgeries",
    totalDates: "total Dates",
    Chat: "Chat",
    Calendar: "Calendar",
    FullCalendar: "Full Calendar",
    SimpleCalendar: "Simple Calendar",
    Shop: "Shop",
    Cart: "Cart",
    Set: "Set",
    CommingSoon: "Coming Soon",
    Change: "Change",
    WishList: "Wish List",
    OnlineAppointmentJoinAlert: "You are the coordinator of the call which terminates automatically after 40 minutes. You are kindly requested to join on time.",
    // OnlineAppointmentJoinAlert: "Dear Mr/Ms. Please note that online appiontment has maximum time is 40 minutes So you must join on time. ",
    Checkout: "Checkout",
    UI: "UI",
    DataList: "Data List",
    ListView: "List View",
    ThumbView: "Thumb View",
    Grid: "Grid",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Colors",
    Card: "Card",
    Basic: "Basic",
    Statistics: "Statistics",
    Analytics: "Analytics",
    CardActions: "Card Actions",
    CardColors: "Card Colors",
    Table: "Table",
    agGridTable: "agGrid Table",
    Components: "Components",
    Alert: "Alert",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Button",
    ButtonGroup: "Button Group",
    Chip: "Chip",
    Collapse: "Collapse",
    Dialogs: "Dialogs",
    Divider: "Divider",
    DropDown: "DropDown",
    List: "List",
    Loading: "Loading",
    Navbar: "Navbar",
    Notifications: "Notifications",
    Pagination: "Pagination",
    Popup: "Popup",
    Progress: "Progress",
    Sidebar: "Sidebar",
    Slider: "Slider",
    Tabs: "Tabs",
    Tooltip: "Tooltip",
    Upload: "Upload",
    FormsAndTable: "Forms & Table",
    FormElements: "Form Elements",
    Select: "Select",
    Switch: "Switch",
    Checkbox: "Checkbox",
    Radio: "Radio",
    Input: "Input",
    NumberInput: "Number Input",
    Textarea: "Textarea",
    FormLayouts: "Form Layouts",
    FormWizard: "Form Wizard",
    FormValidation: "Form Validation",
    FormInputGroup: "Form Input Group",
    Pages: "Pages",
    Authentication: "Authentication",
    Login: "Login",
    Paymentconditions: "Payment conditions",
    Cancellationconditions: "Cancellation policy",


    Register: "Register",
    registerasanew: "register as new ",
    ForgotPassword: "Forgot Password",
    ResetPassword: "Reset Password",
    LockScreen: "Lock Screen",
    Miscellaneous: "Miscellaneous",
    ComingSoon: "Coming Soon",
    404: "404",
    500: "500",
    NotAuthorized: "Not Authorized",
    Maintenance: "Maintenance",
    Profile: "Profile",
    Invoice: "Invoice",
    FAQ: "FAQ",
    Search: "Search",
    view: "view",

    SearchBy: "Search By",
    KnowledgeBase: "Knowledge Base",
    ChartsAndMaps: "Charts & Maps",
    Charts: "Charts",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Map",
    Extensions: "Extensions",
    QuillEditor: "Quill Editor",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Datepicker",
    DatetimePicker: "Datetime Picker",
    AccessControl: "Access Control",
    Areyouasmoker: "Are you a smoker",
    I18n: "I18n",
    Carousel: "Carousel",
    Clipboard: "Clipboard",
    ContextMenu: "Context Menu",
    StarRatings: "Star Ratings",
    Autocomplete: "Autocomplete",
    Tree: "Tree",
    Import: "Import",
    Export: "Export",
    ExportSelected: "Export Selected",
    Others: "Others",
    MenuLevels: "Menu Levels",
    MenuLevel2p1: "Menu Level 2.1",
    MenuLevel2p2: "Menu Level 2.2",
    MenuLevel3p1: "Menu Level 3.1",
    MenuLevel3p2: "Menu Level 3.2",
    DisabledMenu: "Disabled Menu",
    Support: "Support",
    Documentation: "Documentation",
    RaiseSupport: "Raise Support",
    demoTitle: "Card Title",
    demoText: "Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.",
    /* custom i18n data */
    Course: "Course",
    CourseCatalog: "Course catalog",
    Instructor: "Instructors",
    Planning: "Planning",
    AnnualPlan: "Annual Plan",
    Facility: "Facility",
    UploadDate: "Upload Date",
    Doctors: "Doctors",
    ClassRoom: "Class Room",
    HospitalRooms: "Hospital Rooms ",
    CourseType: "Course Type",
    TestsAndReports: "Tests & Reports",
    CourseLevel: "Course Level",
    MaterialType: "Material Type",
    ViewMaterial: "View Material",
    PopulationList: "Population List",
    IndustrySector: "Industry Sector",
    AreaOfStudy: "Area Of Study",
    CourseCategory: "Course Category",
    Nationality: "Nationality",
    MaritalStatus: "Marital Status",
    ContactType: "Contact Type",
    EducationDegree: "Education Degree",
    Customer: "Customer",
    Trainee: "Trainee",
    UserManagement: "User Management",
    Home: "Home",
    YearSummary: "Year Summary",
    SearchDoctors: "Search for Doctors",
    CourseClass: "Course Classes",
    QuestionManagement: "Question Management",
    Reports: "Reports",
    CourseRegistration: "Course Registrations",
    Pleasecompleteyourregistrationtoaccessourservices: "Please complete your registration to access our services",
    CourseRequest: "Course Requests",
    EnrollmentManagement: "Enrollment Management",
    TestManagement: "Test Management",
    CourseExam: "Course Exams",
    QuestionCategory: "Question Category",
    TermsOfUse: "Terms Of Use",
    AcceptTermsConditions: "I accept the terms & conditions.",
    Imprints: "Imprints",
    NewMaterial: "New Material",
    StudentUserInstallment: "Student/User Installment",
    BusTracking: "Bus Tracking",

    ChannelBook: "Channel Book",
    ChannelBookType: "Channel Book Type",
    ChannelBookAddEdit: "Channel Book Add/Edit",
    Sender: "Sender",
    Recevier: "Recevier",
    TranslationServices: "Translation Services.",
    TravelArrangementsPatients: "Travel arrangements.",
    Message: "Message",
    MessageType: "MessageType",
    IsReplyable: "Is Replyable",
    BusSuperVisors: "Bus Super Visors",
    EndDate: "End Date",
    AddSubjects: "Add Subjects",
    ClassTeacherSubject: "Class Teacher Subject",
    Edit: "Edit",
    ThisItemAlreadyExist: "This Item Already Exist",
    AlreadyExist: "Already Exist",
    Material: "Material",
    Logout: "Logout",
    Pdf: "Pdf",
    Video: "Video",
    Image: "Image",
    Document: "Document",
    ExamCode: "Exam Code",
    UpdateImage: "Update Image",
    RemoveImage: "Remove Image",
    WelcomeBack: "Welcome back, please login to your account.",
    RememberMe: "Remember Me",
    SendChannelBook: "Send channel book",
    Data: "Data",
    RelaredAccounts: "Relared accounts ",
    Filters: "Filters",
    StudentInstallmentCounts: "Student installment counts",
    ExamsAndEventsForWeek: "Exams and events for week",
    AllUsers: "All users",
    SurgeryRequest: "Surgery request",
    FollowAppointmentsConsultations: "Follow up appointments after consultations.",
    OnlineAppointmentsOnlineConsultations: "Online appointments for online consultations.",
    ExamsForWeek: "Exams for week",
    EventsForWeek: "Events for week",
    AlltInstallmentCounts: "All installment counts",
    PaidInstallmentCount: "Paid installment count",
    UnPaidInstallmentCount: "UnPaid installment count",
    AllChangeRouteRequest: "All change route request count",
    AllChangeRouteRequestPending: "change route request pending count",
    AllChangeRouteRequestRefused: "All change route request refused",
    AllChangeRouteRequestAccept: "All change route request accept",
    StudentBusOffForWeek: "Student bus off for week",
    studentBusOFF: "student number",
    AllRoutesAndBusses: "Routes and busses",
    StudentChangeRouteRequest: "StudentChangeRouteRequest",
    StudentChangeRouteRequestAddEdit: "Add/Edit",
    Accepted: "Accepted",
    AddEdit: "Add/Edit",
    ColorCode: "Color Code",
    SubjectExamRole: "Subject Exam Role",
    BusTrips: "Bus Trips",
    BusTrip: "Bus Trip",
    TIMEOFSTART: "Time of start",
    TIMEOFEND: "Time of end",
    PickupLocation: "Adress",
    isInTrip: "is in trip ?",
    DaysOfWeek: "Day",
    maxScore: "max score",
    Single: "Single",
    Divorced: "Divorced",
    PatientServices: "Patients' Services",
    Widowed: "Widowed",
    Married: "Married",
    Ifyeshowmany: "If yes, how many",
    ChangePassword: "Change Password",
    OldPassword: "Old Password",
    NewPassword: "New Password",
    Characters: "Characters",
    ContainsNumber: "Contains Number",
    ContainsUppercase: "Contains Uppercase",
    ContainsSpecialCharacter: "Contains Special Character",
    success: "success",
    PasswordChangedSuccessfully: "Password Changed Successfully",
    TitleEN: "TitleEN",
    TitleAR: "TitleAR",
    OtherPhone: "Other Phone",
    Telephone: "Telephone",
    Gender: "Gender",
    medicine: "Medicine",
    Country: "Country",
    ServicesprovidedToPatients: "Services provided to our patients:",
    SeeAllPrescriptions: "See All Prescriptions",
    code: "code",
    Specialities: "Specialities",
    MainFocus: "Main Focus",
    BioEN: "Bio EN",
    BioAR: "Bio AR",
    Education: "Education",
    Ifyesdescripe: "If yes, describe",
    Prescriptiondetailthebelowdataisincludedinyourmedicalprescriptiontemplate: " Prescription details ( the below data is included in your medical prescription template)",
    Haveyougonethroughanysurgerybefore: "Have you gone through any surgery before",
    Experiences: "Experiences",
    Links: "Links",
    SessionPrices: "SessionPrices",
    neverdid: "never did",
    Howmanydrinksperday: "How many drinks per day",
    drinksperday: " drinks per day",
    Cities: "Cities",
    Services: "Services",
    ExtraServices: "Extra Services",
    Extras: "Extras ",
    PaymentType: "Payment Type",
    PaymentStatus: "Payment Status",
    DoctorRequestType: "Doctor Request Type",
    SmokeDegree: "Smoke Degree",
    Position: "Position",
    KinshipType: "Kinship Type",
    ReportType: "Report Type",
    ScanType: "Scan Type",
    AnalysisType: "Analysis Type",
    DocumentType: "Document Type",
    Department: "Department",
    AddanotherItem: "Add another Item",
    Sponsors: "Sponsors",
    Surgeries: "Surgeries",
    SurgeryCategories: "Surgery Categories",
    SurgeryItems: "Surgery Items",
    PaymentPartItem: "Payment Part Item",
    UserNotificationSetting: "User Notification Setting",
    HaveyousufferedfromanydiseasesuchasMalariahepatitisetc: "Have you suffered from any disease such as Malaria, hepatitis, etc.",
    Anycancerdiagnosishistoryinyourfamily: "Any cancer diagnosis history in your family",
    CodeEN: "Code EN",
    CodeAR: "Code AR",
    City: "City",
    MedicalPackagesSurgeries: "  Medical packages (Check-ups, cosmetic surgeries).",
    NewslettersHospitalDoctor: "News letters about newly incorporated hospitals and doctors, new technologies in complicated surgeries, new treatments in some diseases.",
    ConvertValue: "Convert Value",
    Value: " Value",
    Percentage: " Percentage",
    MainSpecialty: "Main Specialty",
    ShowHome: "Show Home",
    Price: "Price",
    SurgeryCategory: "Surgery Category",
    Surgery: "Surgery",
    OnlineAppointment: "Online Appointments",
    SurgeryDate: "Surgery Date",
    Yescurrently: "Yes, currently",
    OnlineAppointmentpayment: "Online Appointment Payment",
    Doyoudrinkalcohol: "Do you drink alcohol",
    SurgeryPayment: "Surgery Payment",
    All: "All",
    ReceiveOffersAndPromotions: "Receive Offers And Promotions",
    Areyoudiagnosedwithloworhighpressure: "Are you diagnosed low or high pressure",
    Highpressure: "High pressure",
    Lowpressure: "Low pressure",
    Areyouasthmatic: "Are you asthmatic?",
    Locallicensenumber: "Local license number",
    Additionalinformation: "Additional information",
    Addcertificates: "Add certificates",
    GraduationDate: "Graduation Date",
    BiographyOptional: "Biography (Optional)",
    Speciality: "Specialty",
    Province: "Province",
    Hospitalname: "Hospital name",
    HospitalImages: "Hospital Images",
    maincontact: "main contact",
    HospitalGroup: "Hospital Group",
    TV: "TV",
    BusStation: "Bus Station",
    ContactPerson: "Contact Person",
    ContactPersonEmail: "Contact Person Email",
    Address: "Address",
    Provinance: "Region",
    Accompanyingperson: "Accompanying person",
    Forinpatientsanaccompanyingpersonisallowedtostaywiththepatientintheroom: "For inpatients, an accompanying person is allowed to stay with the patient in the room",
    advantageschoose: "Why US",
    ReceiveMonthlyNewsletter: "Receive Monthly Newsletter",
    ReceiveInformationPackages: "Receive Information Packages",
    ReceiveInformationRecentlyAddedHospitalsAndDoctors: "Receive Information Recently Added",
    Facilities: "Facilities",
    HospitalInfo: "Hospital Info",
    SurgeryTreatmentIncludes: "Surgery / treatment includes",
    SurgeryTreatmentNotIncludes: "Surgery / treatment excludes",
    SurgeryAndTreatment: "Surgeries & Treatments",
    MedicalDocumentation: "Medical Documentation",
    NumOfChildren: "Number Of Children",
    DiseaseNote: "Disease Note",
    CancerHistoryinFamilyNote: "History Family Note",
    MedicalHistory: "Medical History",
    PatientMedicalHistory: "Patient Medical History",
    UpdateMedicalHistory: "Update Medical History",
    YourMedicalHistory: "Your Medical History",
    Electornicprescription: " Electornic Prescription",
    PrintYourPrescription: "Print Your Prescription",
    OnlineConsultations: "Online Consultations",
    YourOnlineConsultations: "Your Online Consultations",
    RequestOnlineConsultations: "Request Consultation",
    Carparking: "Car parking",
    HaveChildren: "Have Children",
    IsDrinkAlcoholCurrently: "Is Drink Alcohol Currently",
    Clarificationofeachcriteria6cigarettesadayconsideredasmoderate: "Clarification of each criteria: 6 cigarettes a day considered as moderate",
    Submit: "Submit",
    FamilyName: "Family Name",
    PendingDocument: "Pending Documents",
    Payments: "Payments",
    RepeatMedicalOnlineConsultations: "Repeat Medical Online Consultations",
    SurgeriesTreatments: "Surgeries & Treatments",
    ShortAccess: "Short Access",
    YourPatients: " Your Patients ",
    addYourAvailability: "Add Your Availability",
    editYourAvailability: "Edit Your Availability",
    travelArrangmentDiloag: "Please send us an email to travel@doclinia.com",
    travelArrangmentDiloagPlus: "to arrange your travel requirements",
    thisWeekOnlineAppointments: "This Week Online Appointments",
    PendingTask: "Pending Task",
    appointmentPaymentAlert: "The appointment will be blocked for you for 4 hours and then released,",
    DoctorBlockAlert: "You are trying to block a date / time range with booked online appointments.",
    appointmentPaymentAlertPlus: "so please complete your payment as soon as possible",
    DoctorBlockAlertPlus: "Your request has been sent to your account manager to modify the appointments and release the requested date and time.",
    MedicalReportsHospitalSending: " Your medical reports are sent to the hospital",
    OK: "OK",
    YourMedicalReport: "Your Medical Report",
    YourMedicalReports: "Your Medical Report",
    CorrespondingDepartmentMessage: "The corresponding department will send you a message with",
    AppointmentDate: "Appointment Date",
    PendingMedicalDocuments: "Pending medical documents",
    PendingMedicalReports: "Pending medical Reports",
    Repeat: "Repeat",
    DownloadPrescription: "Download Prescription",
    PatientsHistoryMedicalService: "Patients’ history displayed for each patient with all medical history performed through the platform.",
    DoctorsVisitsDestination: "Doctors’ visits to destination for on-site surgeries and consultations.",
    PatientDiagnosis: "Patient Diagnosis",
    Dossage: "Dosage",
    Duration: "Duration In Days",
    Dr: "Dr",
    Destination: "Destination",
    Yesbutoccasionallyrarely: "Yes,but occasionally/rarely",
    Certificates: "Certificates",
    Biography: "Biography",
    SelectATimeFor: "Select a time for",
    onlineAppointmentSpecificDateMessage: "If you wish to request an online appointment on a specific date, please contact us on",
    //onlineAppointmentSpecificDateMessage: "In case you wish to make an online appointment on this specific date and in spite of online unavailability, Please send a request to our patient service agents to manage your booking request at ",
    CardHolder: "Card Holder",
    CardNumber: "Card Number",
    ExpiryDate: "Expiry Date",
    CVV: "CVV ",
    SpecialistDelectedTopDestination: "Our Specialist in the selected top destination",
    TopDestinations: "Our Top Destinations",
    DoctorRecordWithId: "Doctor Record With Id",
    NotFound: "Not Found",
    SearchPatient: "Search for Patient",
    PatientDiagnosisPrescription: "Patient Diagnosis & Prescription",
    NextOnlineAppointment: "Next Online Appointment",
    PrescriptionDetailsTitle: " Prescription details ( the below data is included in your medical prescription template)",
    Cafeteria: "Cafeteria",
    Toilets: "Toilets",
    PayNow: "Pay Now",
    CallLink: "Call Link",
    MedicalReportsHospitalSendingFollow: "The corresponding department will send you a message with",
    ConfirmedDate: "the Confirmed Date",
    OnlineappointmentNeeded: "Online appointment if needed",
    MedicalDocumentsNeeded: " Medical Documents Needed",
    AdditionalRequestedDocuments: "Additional Requested Documents",
    PaymentDateAccount: "Payment Date and Bank Account",
    TravelArrangements: "Travel Arrangements",
    medicalReportsHospitalHeading: " In order to send your medical reports to the hospital forthe surgery date confirmation , please pay the deposit fee.This fee is discounted from the total of surgery price.",
    RemainingAmountPaidMessage: "The remaining amount is paid directly to the doctor and hospital after confirming the date and 14 days prior to the surgery or treatment.",
    PatientMedicalReports: "Patient  Medical Reports",
    PatientMedicalReportAndRequesteds: "Patient  Medical Reports & Requested",
    PatientsSymptomsDescription: "Patient's Symptoms Description",
    Time: "Time",
    CopyZoomLink: "Copy Zoom Link",
    ScheduleAnotherAppintment: "Schedule Another Appintment",
    DoctorsNotes: "Doctors Notes",
    Furtherrequestedscansanalysis: "Further requested scan/analysis",
    PatientDiagnosisNote: "Patient Diagnosis Note",
    PrescriptionDetails: "Prescription details ( the below data is included in your medical prescription template)",
    AdditionalInformation: "Additional Information",
    LicenseNumber: "License Number",
    AddCertificates: "Add Certificates",
    PlaceOfWork: "Place Of Work",
    Typeyourbiographyhere: "Type your biography here",
    YouNextOnlineAppointment: "You Next Online Appointment",
    FutureSurgeries: "Future Surgeries",
    NoDataAvailable: "No Data Available",
    EditProfile: "Edit Profile",
    OnlineConsultationPrice: "Online Consultation Latest Price",
    PreviewOnCalendar: "Preview On Calendar",
    ChooseDays: "Choose Days",
    ChooseMonthsoptional: "Choose Months",
    From: "From",
    To: "To",
    January: "January",
    February: "February",
    Mars: "Mars",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    Sun: "Sun",
    Mon: "Mon",
    Wednes: "Wednes",
    Thurs: "Thurs",
    Fri: "Fri",
    Sat: "Sat",
    Tues: "Tues",
    StartDate: "Start Date",
    DoctorBlockAvailabilty1: "You are trying to block a date / time range with booked online appointments.",
    DoctorBlockAvailabilty2: "please send your request to your account manager to modify the appointments and release the requested date and time.",
    AppointmentPatientNote: "Your online appointment is confirmed. You will be receiving an email with the online call link.",
    AppointmentPatientNotePlease: "Please describe the symptoms to the doctor:",
    AppointmentPatientNoteUpload: "!Please Note: Please upload your medical reports, scans, blood analysis or any other documents related to appointment on your profile.",
    AddAvailability: "Add Availability",
    PendingTaskDoctor: "Pending Task Doctor",
    DoctorAddAvailability: "Doctor Add Availability",
    DoctorEditAvailability: "Doctor Edit Availability",
    AddAvailabilityHint: "*The appointment intervals are 30 minutes",
    ContainsLowercase: "Contains Lowercase",
    EditPassword: "Edit Password",
    onlineappointmentNotification: "online appointment Notification",
    YourNotification: "Your Notification",
    OnlineReleas: "online appointment payment is due (Four hour before appointment release)",
    permissions: "Permissions",
    NewsMonthely: "Do you wish to recive your monthly newsLetter",
    InfoPackage: "Do you wish to receive information about our packages",
    InfoHospital: "Do you wish to receive information about recently added hospitals and doctors",
    Promotion: "Do you wish to receive offers and promotions",
    SergeryPayment: "Surgery payment due(14 days before surgery date)",
    SergeryNotify: "Surgery date Notification (2 days before surgery date )",
    SessionBlockMessage: "You can't proceed this session because it is Blocked",
    sessionOldSelected: "You can't proceed this session because it is reserved",
    SessionSelected: "Session Selected",
    SessionBlocked: "Session Blocked",
    MoreDetails: "More Details",
    AcceptPatientAbove18Years: "I declare that I am above 18 years old.",
    surfaceDescribtion: "Our consultants will guide you throughout your cure journey",
    dollarDescribtion: " Avoid the long administrative procedures to confirm your surgeries abroad",
    clockDescribtion: "Immediate online confirmation on your doctors' appointments. Second opinios with international prestigious doctors reachable from any part of the world ",
    HairPackageDescribtion: "Hair Treatment Packages",
    EnglishLangTitle: "English",
    ArabicLangTitle: "العربية",
    PastDateAlert: "The day that you have selected is past",
    PastDate: "Past Date",
    DurationInMinutes: "Duration in minutes",
    DoctorSessionDetails: "Doctor Session Details",
    DoctorSearch: "Doctor Search",
    TreatmentsProfile: "Treatment details",
    DoctorProfileData: "Doctor Profile Data",
    serviceNote1: "Visa assistance Services for patients & companions",
    Flights: "Flights",
    Transfers: "Transfers",
    serviceNote2: "Hotel booking for patients & companions",
    ServiceProvider: "Services provided to our patients:",
    sendUS: "Send us your enquiry to",
    Continueasguest: "Continue As Guest",
    ChooseDocumentType: "Choose Document Type",
    DateFrom: "Date From",
    DateTo: "Date To",
    Package: "Package",
    ThisPackageincludes: "This Package includes",
    ThisPackagedoesnotinclude: "This Package does not include",
    ApplyforPackage: "Request Package",
    PreferredDate: "Preferred Date",
    HowManyPassengers: "Number Of Companions",
    NumberOfBeneficiary: "Number Of Beneficiaries",
    UserName: "User Name",
    Clear: "Clear",
    Accommodation: "Accommodation",
    PreferredLanguage: "Preferred Language",
    Specialties: "Specialties",
    AddSpecialties: "Add Specialties",
    AddDoctors: "Add Doctors",
    ManagePayments: "Manage Payments",
    packages: "packages",
    NumberOfPassenger: "Number Of Passenger",
    SubSpecialties: "Sub Specialties",
    FilterBySpecialty: "Filter By Specialty",
    AddSubSpeciality: "Add Sub-Speciality",
    SubSpecialities: "Sub-Specialities",
    AddTreatments: "Add Treatments",
    AddSpeciality: "Add Speciality",
    AddSubSpecialities: "Add Sub-Specialities",
    FilterBySubSpecialty: "Filter By Sub-Specialty",
    CreateNewDoctorProfile: "Create New Doctor Profile",
    GotoTreatment: "Go to Treatment",
    SurgeryTreatment: "Surgery & Treatment",
    SurgeryDetails: "Surgery Details",
    AddAssignDoctorsToSpecialties: "Add/Assign Doctors to Specialties",
    AddHospitalSpecialities: "Add Hospital Specialities",
    AboutHospital: "AboutHospital  ",
    HospitalSpecialities: "Hospital Specialities",
    AddHospitalSubSpecialities: "Add Hospital Sub-Specialities",
    AddAssignDoctorstoSurgeriesTreatments: "Add/Assign Doctors to Surgeries & Treatments",
    FilterBySurgery: "Filter By Surgery",
    RequiredDocs: "Request Document",
    RequestedFrom: "Requested From",
    AssignDoctors: "Assign Doctors",
    PaymentStatus: "Payment Status",
    EditTreatment: "Edit Treatment / Surgery",
    AddTreatments: "Add Surgeries & Treatments",
    CreateNewTreatment: "Create New Treatment/Surgery",
    Treatments: "Treatments",
    Edit: "Edit",
    Info: "Info",
    editTreatmentSurgery: "please save selected surgery first",
    New: "New",
    SessionPrice: "Session Price",
    Packages: "Packages",
    NextAppointment: "Next Appointment",
    confirmCancelSessionText1: "You are going to cancel your Online Appointment",
    confirmCancelSessionText2: "Are you sure?",
    Checkuppackages: "Our Top Selling Packages",
    FindYourDoctor: "Find Your Doctor",
    FOLLOWUS: "FOLLOW US",
    NEWSLETTER: "NEWSLETTER",
    PleaseSelectOnOneOfSpecialities: "*Please Select On One Of the Specialities",
    SetDate: "Set Date",
    ChooseMainSpecialty: "Choose Main Specialty",
    ChooseSubSpecialty: "Choose Sub-Specialty",
    DoctorSpecilties: "Doctor Specialties",
    DoctorExperiences: "Doctor Experiences",
    DoctorLanguages: "Doctor Languages",
    OurPackages: "Our Packages",
    CreateNew: "Create New",
    ChoiseDate: "Choice Date",
    //RegisterSuccsessMessage: "Thank you for signing up in Doclinia. Please check your email to proceed.",
    RegisterSuccsessMessage: "Thank you for signing up in Dawayiyni Please Register Your information.",
    DoctorProfile: "Doctor Profile",
    AppointmentPatientCondition: "from the booking date to one week before your online consultation: the cancellation is free of charge From 6 days to 72 hours: 50% of the paid amount is refunded From 72 to consultation date: Non-refundable",
    NotSetYet: "Not set yet",
    CancelSurgeryRequest: "Cancel Surgery Request",
    surgeryCancelConfirm: "Are you sure to cancel surgery",
    ProccedOnlineAppointment: "Booking your online appointment",
    RecommendationLevel: "Recommendation Level",
    RemainingAmountPaid: "Remaining Amount Paid",
    NoData: "No Data",
    NoDataToshow: "No Data To show",
    Procced: "Proceed",
    BackAndChangeYourSelection: "Back And Change Your Selection",
    Book: "Book",
    STEPONE: "Step One :",
    STEPTWO: "Step Two :",
    STEPTREE: "Step Three : ",
    OneSetAppointment: "Set Appointment",
    TwoSetAppointment: "Add Symptoms",
    ThreeSetAppointment: "Payment Method",
    HospitalConfirmNote: "Once you confirm you can't edit",
    Proceed: "Proceed",
    PayLater: "Pay Later",
    ReservationHistory: "Reservations History",
    PatientSymptomNote: "No symptoms added by the patient",
    DiagnosisNote: "Diagnosis Note",
    Top: "Top",
    Destinations: "Destinations",
    Explore: "Explore",
    OurPartners: "Our Partners",
    withDoctor: "with Doctor",
    PaymentInformation: "Payment Information",
    DrInfo: "Doctor Info",
    ReservationSession: "Reservation Session Info",
    PaymentInfo: "Payment Info",
    PendingNote: "the appointment is blocked for 4 hours only and will be automatically released and available to other patients. Please proceed with your payment to confirm your booking",
    AppointmantBookingReservationDetails: "Appointment Reservation Summary",
    SurgeryBookingReservationDetails: "Surgery Reservation Summary",
    ResevrationSessionDetails: "Reservation Session Details",
    CancelBookingAppointmentMessage: "Please Note: you already canceled your current reservation, System will remove your current reservation details Thanks for using docLinia services we are looking forward to see you soon",
    ConfirmNote: "your payment is received and confirmed !",
    ShowMore: "Show More",
    PatientInfo: "Patient Info",
    PreviewHistory: "Preview Booking History",
    SessionID: "Session ID",
    SessionDate: "Session Date",
    ReservitionDate: "Reservation Date",
    PleaseNote: "Please Note",
    ReservationSurgrey: "Reservation Surgrey Info",
    SurgeryID: "Surgery ID",
    SurgeryName: "Surgery Name",
    BookingYourSurgery: "Booking Your Surgery",
    OneSetSurgery: "Set Surgery",
    TwoSetSurgery: "Deposit Fees",
    ThreeSetSurgery: "Three Set Surgery",
    DocumentName: "Document Name",
    PrivacyPolicy: "Privacy Policy",
    TermsAndConditions: "Terms And Conditions",
    ContactUs: "Contact Us",
    PatientWaiver: "Patient Waiver",
    PatientCare: "Patient Care",
    Hospitals: "Hospitals",
    Collaborators: "Collaborators",
    JoinOurTeam: "Join Our Team",
    TreatmentsinthisHospital: "Treatments",
    FindDoctor: "Find Doctor",
    Pleaseselectoneofthose: "Please select one of those",
    PleaseSelectATimeFor: "Please Select A Time For",
    BookingSurgeryTitle: "Surgeries & Treatments Booking",
    DoctorSameSpecialty: "Doctors in the same specialty",
    profileLink: "Profile Link",
    BookTheSurgery: "Book The Surgery",
    Hosptial: "Hosptial",
    DrTitle: "Dr.",
    AppointmentPatientConditionTitle: "Cancellation conditions",
    OneSetPackage: "Set Package",
    DownloadBooking: "Download Booking",
    PackageCategory: "Package Category",
    FindDoctor: "Find Doctor",
    DoctorName: "Doctor Name",
    Pleaseselectoneofthose: "Please select one of those",
    PleaseSelectATimeFor: "Please Select A Time For",
    BookingSurgeryTitle: "Surgeries & Treatments Booking",
    DoctorSameSpecialty: "Doctors in the same specialty",
    profileLink: "Profile Link",
    BookTheSurgery: "Book The Surgery",
    Hosptial: "Hosptial",
    DrTitle: "Dr.",
    AppointmentPatientConditionTitle: "Cancellation conditions",
    AppointmentPatientConditionFirst: "from the booking date to one week before your online consultation: the cancellation is free of charge",
    AppointmentPatientConditionSecond: "From 6 days to 72 hours: 50% of the paid amount is refunded",
    AppointmentPatientConditionThird: "From 72 to consultation date: Non-refundable",
    OneSetPackage: "Set Package",
    DownloadBooking: "Download Booking",
    PackageCategory: "Package Category",
    BranchAddress: "Branch Address",
    BankName: "Bank Name",
    AccountNumber: "Account Number",
    IsAllowBankTransfare: "Is Allow Bank Transfare",
    CantEditSession: "can't edit reservation before 72h , please cancel it and rebooking",
    PaymentSuccess: "Payment process successfully. thank you for the payment",
    Paymentfail: "Payment process Failed. Please Try again",
    Cancellationconditionspop: "Cancellation Conditions",
    paymentLaterHint: "the appointment will be blocked for 4 hours only.please proceed with the payment within the next 4 hours,or else the appointment will be automatically released and available for other patients",
    ProcessingFees: "deposit fee",
    UploadedDocument: "Uploaded already added document",
    ShowYearSummary: "Show Year Summary",
    RequestAppointment: "Request an online consultation",
    cancelledBookings: "Cancelled online appointments",
    PriceStartingFrom: "Price starting from",
    CancelledConfirm: "the cancellation has been completed succesfully",
    PatientHistory: "Patient History",
    MedicalReports: "Medical Reports",
    payOrPaylaterNote1: "Please choose one of the following options:",
    payOrPaylaterNote2: "1. Click on ' confirm' to proceed with the online payment and confirm your booking.",
    payOrPaylaterNote3: "2. Click on 'pay later' to pay max. within the next 4 hours. In case payment is not carried out, your booking will not be confirmed and the appointment will be released and available to other patients.",
    payOrPaylaterNote: "Please choose one of the following options: 1. Click on ' confirm' to proceed with the online payment and confirm your booking. 2. Click on 'pay later' to pay max. within the next 4 hours. In case payment is not carried out, your booking will not be confirmed and the appointment will be released and available to other patients.",
    Canceled: "Canceled",
    HospitalDate: "Hospital seted Date",
    NotSetYet: "Not Set Yet",
    PrescriptionName: "Prescription Name",
    DrAdress: "Dr Address",
    OnlinePay: "Online Pay",
    UpCommingSurgeries: "Upcoming surgeries",
    OldSurgeries: "Completed surgeries",
    PreferDateFrom: "Prefer Date From",
    PreferDateTo: "Prefer Date To",
    CancelledSurgeries: "Cancelled surgeries",
    CancelledConfirm: "the cancellation has been completed succesfully",
    AppointmentPendingDocuments: "Online Appointments",
    PatientHistory: "Patient History",
    MedicalReports: "Medical Reports",
    payOrPaylaterNote1: "Please choose one of the following options:",
    payOrPaylaterNote2: "1. Click on ' confirm' to proceed with the online payment and confirm your booking.",
    payOrPaylaterNote3: "2. Click on 'pay later' to pay max. within the next 4 hours. In case payment is not carried out, your booking will not be confirmed and the appointment will be released and available to other patients.",
    payOrPaylaterNote: "Please choose one of the following options: 1. Click on ' confirm' to proceed with the online payment and confirm your booking. 2. Click on 'pay later' to pay max. within the next 4 hours. In case payment is not carried out, your booking will not be confirmed and the appointment will be released and available to other patients.",
    DaysLeft: "Days left for the appointment",
    NotSetYet: "Not Set Yet",
    PrescriptionName: "Prescription Name",
    OnlinePay: "Online Pay",
    PreferDateFrom: "Prefer Date From",
    PreferDateTo: "Prefer Date To",
    PayProcessingFees: "Pay Deposit",
    ExtraServiceTotalPrice: "Extra Service Total Price",
    CancelledConfirm: "the cancellation has been completed succesfully",
    YourAllPendingDocuments: "Your Pending Documents",
    SurgeryPendingDocuments: "Surgeries & Treatments",
    PatientHistory: "Patient History",
    MedicalReports: "Medical Reports",
    SurgeryPrice: "Surgery Price",
    RemainingSurgeryAmount: "Remaining Surgery Amount",
    PayRemainingSurgeryAmount: "Pay Remaining Surgery Amount",
    payOrPaylaterNote: "you can click pay now for pay or pay later and you can pay from your online consultation from profile.",
    HoursLeft: "Hours remaining for payment",
    MinutsLeft: "Minutes remaining for payment",
    NotSetYet: "Not Set Yet",
    PrescriptionName: "Prescription Name",
    OnlinePay: "Online Pay",
    PreferDateFrom: "Prefer Date From",
    PreferDateTo: "Prefer Date To",
    UnPayedExtraService: "Unpayed Extra Service",
    PayExtraServices: "Pay Extra Services",
    Collaborate: "Collaborate",
    hospitals: "hospitals",
    HaveDisease: "Have you suffered from any disease such as Malaria, hepatitis,etc.?",
    DiseaseText: "Disease",
    Ifyeswhen: "Ifyes, when",
    AidsDate: "Disease date from:",
    HaveAids: "Have you been diagnosed aids?",
    HaveHeartProblem: "Have you suffered from liver or heart problems?",
    LastBloodAnalysis: "Last blood analysis date",
    Allergies: "Allergies(to Medications or food)",
    Medication: "Medication",
    MedicineName: "Medicine Name",
    Doosage: "Doosage",
    DurationInDay: "Duration in day",
    ReservationDetail: "Reservation Detail",
    EditAvailability: "Edit Availability",
    GeneratInvoice: "Generate Invoice",
    CantPaylater: "sorry you can't pay later",
    PassengerNote: "the price for travel will be sent by our travel department",
    DocliniaComName: "Cure Journey Portal",
    DocliniaComAddress: "Souk Al Bahar, Saha offices C,",
    DocliniaComCity: "office 202, Dubai,UAE",
    InvoiceTitleReport: "Invoice",
    INVOICETO: "INVOICE TO",
    OnlineConsultation: "Online Consultation",
    DoctorNameRep: "Doctor's name",
    HospitalNameRep: "Hospital's name",
    USD: "USD",
    SUBTOTAL: "SUBTOTAL",
    VAT: "VAT",
    TOTAL: "TOTAL",
    //FooterRep: "Thank you for your confidence in Doclinia.com",
    PricingAvailNote: "prices are not editable once saved, please contact your account manager for price changes",
    DurationByDay: "Duration By Day",
    PendingProcessFees: "Pending Deposit",
    DoctorPrice: "Doctor Price for this month",
    PendingRmainSurgeryAmount: "Pending Remaining Surgery Amount",
    // PaymentconditionDescription: "For surgeries booked for the next 14 days, the payment of the remaining amount should be carried out within 2 calendar days from the hospital's final budget receival and might be requested to be done directly to the hospital from the patient.",
    CancellationconditionDescription: "In case of critical medical conditions or visa rejection: refund is due after deducting the paperwork based on submiting proof of documents to patients.service@doclinia.com",
    PaymentconditionDescriptionFirst: "For surgeries booked 21 days and above, the remaining amount payment should be carried out within 5 days from the patient’s acceptance of the surgery’s final budget.",
    PaymentconditionDescriptionSecound: "For surgeries booked between 15 and 21 days, the payment of the remaining amount  should be carried out within 2 calendar days from the hospital's final budget receival",
    //PaymentconditionDescriptionThird: "For surgeries booked for the next 14 days, the payment of the remaining amount should be carried out within 2 calendar days from the hospital's final budget receival and might be requested to be done directly to the hospital from the patient. ",
    PaymentconditionDescriptionThird: "For surgeries booked up to 20 days, the remaining amount payment should be carried out within 2 days from the patient’s acceptance of the surgery’s final budget.",
    //CancellationconditionDescriptionFirst: "In case of cancellations due to visa rejection or critical medical conditions preventing the travel of the patient, the refund of the surgery final price with the deduction of the paperwork charges will be processed to the patient based on submission of proof document.",
    CancellationconditionDescriptionFirst: "In case of critical medical conditions or visa rejection: refund is due after deducting the paperwork based on submiting proof of documents to patients.service@doclinia.com",
    CancellationconditionDescriptionSecond: "In case of cancellations not related to the visa procedure or patient's case complications, the cancellation of the surgery is as follows:",
    // CancellationconditionDescriptionThird: "From 14 to 7 days to the surgery date: 80% of the total surgery amount is refunded",
    CancellationconditionDescriptionThird: "From (21 to 14) days before the surgery date: Refund of 80% of the total final price of the surgery ",
    //CancellationconditionDescriptionFourth: "From 6 days to the surgery date: 60% of the total surgery amount is refunded.",
    CancellationconditionDescriptionFourth: "From (14 to 1) days before the surgery date: Refund of 50% of the total final price of the surgery ",
    // CancellationconditionDescriptionRemain: "  to proceed with the cancellation refund\"",
    CancellationconditionDescriptionRemain: "we need to add the reason of cancellation, once the patient clicks on \"cancel the surgery\" adding the following note below the reason box: \" Please contact our patient service at patient.service@doclinia.com to proceed with the cancellation refund\"",
    selectSpacificDoctor: "In case you wish to perform the surgery with specific doctor, please contact our patient services department at:",
    deposit: "Deposit fees",
    SurgeryPayRemainNote: "Upon receiving the confirmation of the hospital on your surgery, you can proceed with the payment of the remaing amount",
    SurgeryProcessingNote: "In order to proceed with the confirmation of your surgery, please proceed with the deposit payment",
    NoPrescriptionHasbeenadded: "No Prescription Has been added",
    NoDiagnosisHasbeenadded: "No Diagnosis Has been added",
    EditNote: "Please click on \"edit\" button to insert your notes and then click \"save\"",
    NoNoteHasbeenadded: "No Note Has been added",
    HospitalPrice: "Hospital Price",
    DoctorPriceSurgery: "Doctor Price",
    HospitalDoctor: "Hospital Doctor",
    saveNote: "please click \"save\" if you make any change",
    PleaseAddPrescription: "Please Add Prescription",
    Complete: "Complete",
    BirthDay: "Date of birth",
    Successfully: "Successfully",
    youhavesuccessfullyreservethispackage: "you have successfully reserve this package",
    cancelSurgeryNote: "Please contact our patient service at patient.service@doclinia.com to proceed with the cancellation refund",
    sendMail: "Please send us an email to ",
    TravelArrange: "if you need help with your travel arrangements",
    Count: "Count",
    Done: "Done",
    Add: "Add",
    downloadNote: "Please download the",
    ForPaidSession: "for paid session",
    selectAlreadyAddDoc: "you can select from already added documents or add new",
    PendingPayments: "Pending Payments",
    Close: "Close",
    RateYourExperience: "Rate Your Experience",
    With: "With",
    TopDoctors: "Top Doctors",
    MeetOur: "MEET OUR",
    accessOurDB: "Access our database of global expert specialists, clinics, psychologists.",
    searchByDoctor: "SEARCH BY DOCTOR",
    searchBySpeciality: "Search By Speciality",
    ServicesProvidedToOurPatients: "Services Provided To Our Patients:",
    AddCV: "Add CV In PDF",
    MedicineName: "Medicine Name",
    TradeName: "Trade Name",
    ActiveIngredients: "Active Ingredients",
    HospitalDoctorSpecialities: "Hospital Doctors & Specialities",
    AddNewSurgeryNote: "please note, this new surgery will be reviewed by admin team first to be added in your surgeries",
    CallUSTextUS: "Call us / text us",
    AcceptPaymentText: "We accept online payments using",
    AcceptPaymentTextSecond: " VISA and MasterCard credit/debit card in USD",
    AboutUs: "About us",
    Strategic: "Strategic Alliances",
    DesAboutUS: "Doclinia, a brand of Cure Journey Portal, a medical tourism portal based in UAE, is a complete digital health platform, transforming the traditional medical tourism to a globally reachable service.\nThrough Doclinia the patients can book online appointment, carry out video calls with international doctors, upload photos of their injuries or lesions, submit their scans,\n blood tests and analysis to the corresponding doctors, ask for a second opinion, follow up and get into a surgical intervention scheduled in doctors destination, or in their home country through on-site visits of international specialists.\nThis would be a health network for any patient to reach prestigious physicians abroad, without any need to bear extra travel costs nor countries boundaries restrictions.",
    OurVision: "Our vision",
    DesVision: "Creation of an international medical and health network to serve patients, shifting the medical tourism industry to an accessible and fully digitalized service.",
    Ourmission: "Our mission",
    DesOurmission: "The mission of Cure Journey is to provide international health services through a portfolio of eminent doctors and hospitals to our patients.\nAn accessible and inexpensive way to ask for second opinions with doctors abroad, follow up on patients’ treatment or prepare for any treatment or surgical intervention.",
    Doctors: "Doctors",
    Headcompetition: "Be ahead of the competition",
    Bringexpertise: "Bring your expertise to the digital world",
    Ehealth: "E-Health is the future.",
    Joining: "When joining Doclinia platform, you will: ",
    phase1: "- Register free of any charges.",
    phase2: "- Receive your fees in your bank account.",
    phase3: "- Provide online consultations to our international patients.",
    phase4: "- Manage your availability through a dynamic calendar.",
    phase5: "- Receive your surgeries, treatments and online apppointments in a fully automated process .",
    RegNow: "Sign up to get your first international patients with Doclinia",
    HaveQuestion: "Got any questions?",
    ListYourHospital: "List your hospital on Doclinia",
    WillContactPatient: "We will connect you to patients from all around the world.",
    WhyDoclinia: "Why Doclinia?",
    DocInfo1: "- Connect to new patients from the GCC, Middle East and North Africa. ",
    DocInfo2: "- Open a new distribution channel for your hospitals.",
    DocInfo3: "- Promote the new surgeries' techniques in other regions.",
    DocInfo4: "- Dedicated account manager for your hospital.",
    DocInfo5: "- Patients digital records.",
    DocInfo6: "- GDPR and HIPAA compliant.",
    Signinto: " Sign up to receive your first international patients through Doclinia",
    Strategicalliances: "Strategic alliances",
    Beapart: "Be part of the movement",
    Part1: "- At Doclinia we believe that the union is the essence of strength and growth.",
    Part2: "- If you are a medical tourism company in your destination, with hospitals and doctor’ contracts, benefit from our wide distribution and patients’ portfolio.",
    Part3: "Being our collaborator will grant you:",
    Part4: "- The promotion of your services through Doclinia.",
    Part5: "- Dedicated marketing campaigns with AI tools to reach the international patients.",
    Part6: "- The transition from the traditional offline booking process to a fully digitized platform.",
    Part7: "- The patients’ awareness’ increase on the medical tourism in your destination.",
    OurTarget: "We are targeting a revolution in the digital health services to be available for worldwide patients.",
    LetUs: "and let’s discuss how we can grow together",
    JoinTeam: "Join our team",
    HospitalProfile: "Hospital Profile",
    P1: "Great people make a great place to work. ",
    P2: "In Doclinia we believe in the all-time proverb “Health is wealth” and therefore we strive to offer high- quality service to our patients in their cure journey.",
    P3: "We are in a growth industry operating in an international environment with a clear objective to change the health industry into an easy and reachable service to any patient, overcoming bureaucracies and boundaries. Honesty and integrity are our core values we share with our patients, collaborators, and shareholders.",
    P4: "If you think that you share the same values, you are passionate about what you do, join our team to grow and add a new experience to your professional career.",
    P5: "If you think you share the same values and are passionate about what you do, join our team to grow and add new experience to your career",
    SendUSCV: "Send us your CV to",
    ToBePart: "to be part of an innovative digital health services team.",
    CanNotCompleteSession: "Can Not Complete Future Session",
    CanNotCompleteFutureSession: "You cannot complete future appointments ",
    PaymentFailed: "Payment Failed ,please try again",
    PaymentSuccess: "Payment done Successfully",
    HospitalRoomsEN: "Hospital Rooms EN",
    HospitalInfEN: "Hospital Info EN",
    HospitalRoomsAR: "Hospital Rooms AR",
    HospitalInfAR: "Hospital Info AR",
    ConfirmEditAvailability: "Do you wish to keep the initial dates or not?",
    Recommended: "Recommended",
    PatientsReviews: "Patients reviews",
    HighstToLowestPrice: "Highst to lowest price",
    LowestToHighstPrice: "Lowest to highst price",
    Filter: "Filter",
    DoctorAndOnlineConsultation: "Doctor and online consultation",
    avoid: " AVOID THE LONG ADMINSTRATIVE",
    avoiddesc: "Procedures to confirm your surgeries abroad.",
    consultants: " OUR CONSULTANTS",
    consultantsdesc: " Will guide you throughout your cure journey.",
    immediate: "IMMEDIATE ONLINE CONFIRMATION",
    immediatedesc: "On your doctors appointments. Second opinions with international prestigious doctors reachable from any part of the world.",
    silderheader: "MAKING GLOBAL MEDICAL ",
    silderheader1: "CARE ACCESSIBLE LOCALLY",
    sliderheaderdesc: "Doclinia connects you to private",
    sliderheaderdesc1: " doctors worldwide",
    sent1: "  Doctors checked and verified by us",
    sent2: "Global network of docotrs and specialists",
    sent3: "Treatemnt in the best hospitals in the world",
    sent4: " Book an in-person or online doctor visits minutes",
    PleaseLoginOrRegisterFirst: "Please Login Or Register First",
    Emailus: "Email us",
    LandingPage: "Landing Page",
    Here: "Here",
    For: "To",
    CollaborateJoinOurTeam: "JOIN OUR TEAM",
    CollaborateStrategic: "COLLABORATE STRATEGIC",
    CollaborateHospital: "Collaborate Hospital",
    CollaborateDoctor: "Collaborate Doctor",
    BookAnOnlineAppointment: "Book an online appointment",
    BookAnMedicalPackage: "book an Medical Package",
    RemainingAmount: "Remaining amount",
    CancelreservedMedicalPackage: "Cancel Medical Package",
    ResetFilter: "Reset Filter",
    HospitalInfo: "Hospital Info",
    PersonInfo: "Person Info",
    AccompanyingPerson: "Accompanying Person",
    PackageDetails: "Package Details",
    ReservePackage: "Reserve Package",
    GoToReservation: "Reservation Package",
    SelectHospitalSurgery: "Please Select hospital Surgery",
    NotificationAndPermissions: "Notification and permissions",
    AboutHospital: "About Hospital",
    Include: "Include",
    ExClude: "Exclude",
    TermsAndCondetions: "Terms And Condetions",
    CheckOTP: "Check Security Code",
    CheckCode: "Check Code",
    OurDoctor: "Our Doctor",
    OnlineDoctorDes: "Search about doctor online who can start online appointment",
    MedicalSpecialtiesDes: "Search for doctors who specialize in specific medical specialties",
    AlliedMedicalSpecialtiesDes: "Search for specialists in sub-specialties",
    OurDoctors: "Our Doctors",
    OurDoctorsDes: "We have the best medical team specialized in different medical fields. Get to know our team",
  },
  ar: {
    BrowseAll:"تصفح الجميع",
    OurDoctorsDes: "نحن لدينا افضل فريق طبي متخصص في مجالات طبية مختلفة تعرف علي فريقنا",
    OurDoctors: "دكاترة",
    OurDoctor:"اطباءنا",
    AlliedMedicalSpecialtiesDes: "البحث عن مختصيين في التخصصات الفرعية ",
    MedicalSpecialtiesDes: "البحث عن اطباء مختصيين في التخصصات الطبيية المحددة",
    OnlineDoctorDes: "ابحث عن طبيب عبر الإنترنت يمكنه بدء موعد عبر الإنترنت",
    OurDoctor: "دكاترة",
    CheckCode: "تاكيد الرقم",
    CheckOTP: "التاكد من الرقم السري",
    OnlineDoctor: "الاطباء الاونلاين",
    MedicalSpecialties: "التخصصات الطبية",
    AlliedMedicalSpecialties: "التخصصات الطبية المساعدة",
    ISHaveParentDiseasesProblem: "هل يعاني احد والديك بأي امراض مزمنة او سرطانية",
    ISHaveBrothorsDiseasesProblem: "هل يعاني احد الأخوة بأي امراض مزمنة او سرطانية",
    IsBrother: "أخ /أخت",
    BrothorsDiseasesName: "اسم مرض الأخوة",
    ISHaveAnyDiseasesProblemNow: "هل تعاني من اي امراض حاليا",
    ReasoneDiseasesProblemNow: "سبب المرض حاليا",
    PeriodDiseasesProblemNow: "مدة المرض حاليا",
    ParentDiseasesName: "اذكر صلة القرابة + نوع المرض",
    ISHaveParentDiseasesProblem: "هل يعاني احد والديك بأي امراض مزمنة او سرطانية؟",
    ISHaveSensitiveProblem: "هل لديك حساسية ادوية او حساسية اكل؟",
    PetName: "اسم الحيوان",
    ISHavePet: "هل تقوم بتربية احد الحيوانات في المنزل",
    ISHaveHistoryHospitalization: "هل لديك تاريخ مسبق للتنويم في المستشفى ؟",
    ReasonOfHistoryHospitalization: "سبب للتنويم في المستشفى ونسبة التحسن",
    IsSurgeryBefore: "هل سبق وقمت بأي نوع من أنواع العمليات الجراحية؟",
    ISHaveAnyCancerType: "هل تعاني من أي نوع من انواع السرطان؟",
    ISHaveVisionProblem: "هل تعاني من مشاكل في النظر؟",
    ISHaveRheumaticProblem: "هل تعاني من امراض روماتيزمية (مثل التهاب المفاصل,..)؟",
    ISHaveBlodProblem: "هل تعاني من اي أمراض في الدم؟",
    ISHaveReproductiveSystemProblem: "هل تعاني من امراض الجهاز التناسلي",
    ISHaveDigestiveProblem: "هل تعاني من أمراض في الجهاز الهضمي(مثل القولون العصبي)؟",
    ISHavePsychiatricDisturbances: "هل تعاني من اضطرابات نفسية مثل(مثل الأكتئاب ,الأنفصام,ثنائي القطب)؟",
    ISHaveInfectiousDiseaseBefore: "هل سبق وتم تشخيصك بأمراض معدية (مثل التهاب الكبد الفيروسي ,الأيدز,الانفولنزا الموسمية,كورونا..)؟",
    ISHaveChestProblem: "هل تعاني من اي أمراض في الصدرية؟",
    ISHaveNeurologicalProblem: "هل تعاني من اي أمراض عصبية؟",
    IsHaveNutritionalSupplements: "هل تستخدم مكملات غذائية , مثل الفيتامينات او الاعشاب الطبية سواء بأستمرار او من فترة لأخرى",
    CanAddAttMedicine: "بأمكانك اضافة صورة او ملف بأسم العلاج",
    IsHaveMedications: "هل تستخدم اي نوع من انواع الادوية الطبية سواء لمرض معين او لا؟",
    NumberOfBloodTransfusion: "عدد مرات نقل الدم اليك ",
    ISHaveBloodTransfusion: "هل سبق وتم نقل الدم اليك؟",
    NumberOfCigarettesPerDay: "عدد السجائر التقريبية في اليوم",
    MentionIt: "اذكرها",
    IsHaveChronicDiseases: "هل تعاني من اي أمراض مزمنة (مثل سكري,ضغط ,كسل في الغدة,..او غيرها)",
    IdNumber: "رقم الهوية",
    NameEN: "الاسم الاول انجليزي",
    SecondNameEN: "الاسم الثاني انجليزي ",
    ThirdNameEN: "الاسم الثالث انجليزي",
    FamilyNameEN: "اسم الغائلة انجليزي",
    NameAR: "الاسم الاول عربي",
    SecondNameAR: "الاسم الثاني عربي",
    ThirdNameAR: "الاسم الثالث عربي",
    FamilyNameAR: "اسم العائلة عربي",
    OurPackages2: " تمتع بالعديد من أنظمة الباقات المصممة خصيصا",
    Include: "يتضمن",
    ExClude: "لا يتضمن",
    AddHospitalSubSpecialites: "اضافة تخصصات فرعية",
    ParentSpecialities: "التخصصات الاساسية",
    AddHospitalSpecialities: "اضافة تخصصات",
    DoctorsSelected: "الاطباء المحددة",
    AddTreatmentSurgery: "اضفة/تعديل عملية جراحية",
    HospitalSpecialty: "تخصصات المستشفي",
    ReservedSurgery: "الجراحات المحجوزة",
    HospitalPayments: "مدفوعات المستشفي",
    hospital: "مستشفي",
    HospitalSetting: "تعديل بيانات المستشفي",
    Settings: "الإعدادات",
    SpecialtiesHome: "مجموعة التخصصات التي توفرها المستشفي",
    DoctorsHome: "مجموعة الأطباء داخل المستشفي",
    AddSpecialtiesHome: "تحكم في تخصصات المستشفي",
    AddDoctorsHome: "تحكم في أطباء المستشفي",
    SurgeriesTreatmentsHospitalHome: "جميع العمليات الجراحية الخاصة بالمستشفي",
    PaymentStatusHome: "تعرف علي حالات الدفع الان",
    CompletedRegisterationData: "استكمال بيانات التسجيل",
    Licences: "الرخص",
    MoreInfo: "معلومات اضافية",
    YourPatient: "مرضاك",
    AboutHospital: "عن المستشفي",
    NotificationAndPermissions: "الاشعارات والاذون",
    DoctorYearSummaryAppointments: "ملخص مواعيد السنة",
    DoctorWeeklyAppointments: "المواعيد خلال هذا الاسبوع",
    SelectHospitalSurgery: "من فضلك اختار عملية لاحدي المستشفيات اولا",
    SurgeriesTreatmentsHome: "لديك عمليات جراحية سوف تقوم بها ",
    thisWeekOnlineAppointmentsHome: "اعرف مواعيدك في هذا الاسبوع",
    PendingTaskHome: "ستجد هنا كل المهام المعلقة الخاصة بك",
    editYourAvailabilityHome: "قم بتعديل مواعيدك بكل سهولة",
    addYourAvailabilityHome: "اذا لم تضف اي مواعيد لك من قبل قم بذلك الان",
    OnlineAppointmentHome: "تأكد من مواعيدك المستقبلية في جدولك",
    YourPatientsHome: "ستجد هنا كل المرضي الخاصين بك ..",
    Dashboards: "لوحة القيادة",
    RepeatMedicalOnlineConsultationsHome: "يمكنك معرفة مواعيد طبيبك القادمة لحجز استشارتك مع نفس الطبيب ",
    PrintYourPrescriptionHome: "يمكنك الرجوع الي الروشتات الطبية المرفوعة من قبل الطبيب في اي وقت وطباعتها بكل سهولة",
    RequestOnlineConsultationsHome: "اختر دكتورك المفضل في كل التخصصات و في كل انحاء العالم واحجز موعدك بكل سهولة",
    YourOnlineConsultationsHome: "ستجد هنا كل مواعيدك المستقبلية والملغية وايضا المنتهية",
    PackagesHome: "تابع تفاصيل الباقات التي تم الاشتراك بها وتتبع وقت انتهائها",
    SurgeriesTreatmentsHome: "تفاصيل حجوزاتك للعمليات الجراحية وتعديل اجراءاتها",
    PendingPaymentsHome: "يجب دفع جميع المدفوعات المعلقة للمواعيد والعمليات الجراحية لأستكمال مواعيدك",
    PendingDocumentHome: "يجب تحميل جميع الوثائق المعلقة من المواعيد والعمليات الجراحية",
    YourMedicalReportsHome: "كل المستندات المرفوعة من قبل سوف تجدها هنا ويمكنك تحميلها مره اخرة او مسحها",
    UpdateMedicalHistoryHome: "قم بتعديل بياناتك الطبية ليتمكن طبيبك من تحليل حالتك الصحية جيدا",
    ChooseAnotherDoctor: "اختيار طبيب او ميعاد اخر",
    SessionDetails: "تفاصيل الجلسة",
    GoToReservation: "حجز العملية",
    PackageDetails: "تفاصيل الباقة الطبية",
    ReservePackage: "حجز الباقة الطبية",
    ChooseDoctor: "اختر دكتور",
    PleaseSelectOnADoctor: "من فضلك اختار طبيب لتتمكن من الحجز ",
    AccompanyingPerson: "بيانات الشخص المرافق",
    PersonInfo: "بيانات الشخصية",
    HospitalInfo: "بيانات المستشفي",
    Surgeryprofile: "ملف الجراحة",
    PatientRepeatMedical: "تكرار الجلسات",
    SaveChanges: "حفظ التغييرات",
    PatientPaymentPending: "المدفوعات المؤجلة",
    Actions: "الحركات",
    MainData: "البيانات الاساسية",
    Remove: "مسح",
    welcInSign: "الرجاء تسجيل الدخول إلى حسابك وبدء المغامرة",
    WelcometoDaaweny: "👋 اهلا بك في داويني",
    or: "او",
    Signininstead: "قم بتسجيل الدخول بدلاً من ذلك",
    Alreadyhaveanaccount: "لديك حساب بالفعل؟",
    ResetFilter: "أعادة",
    CancelreservedMedicalPackage: "إلغاء الحجز",
    Back: "رجوع",
    Proceed: "نفذ",
    RemainingAmount: "المبلغ المتبقي",
    BookAnMedicalPackage: "حجز الباقة الطبية",
    BookAnOnlineAppointment: "حجز موعد عبر الانترنت",
    CollaborateDoctor: "تعاون الأطباء",
    CollaborateHospital: "تعاون المستشفيات",
    CollaborateStrategic: "تعاون إستراتيجي",
    CollaborateJoinOurTeam: "انضم إلى فريقنا",
    For: "ل",
    Here: "هنا",
    Emailus: "راسلنا",
    LandingPage: " صفحتي الرئيسية",
    PleaseLoginOrRegisterFirst: "سجل الدخول او ابدأ بالتسجيل",
    Filter: "ترتيب",
    silderheader: " جعل الرعاية الطبية ",
    silderheader1: "العالمية فى متناول الجميع",
    sliderheaderdesc1: "الدوليين في جميع أنحاء العالم",
    sliderheaderdesc: "تصلك دوكلينيا بجميع الأطباء ",
    sent1: "يتم التحقق من الأطباء قبل إنضمامهم",
    sent2: "شبكة عالمية من الأطباء المتخصصين",
    sent3: "علاج فى أفضل مستشفيات العالم",
    sent4: "حجز موعدًا عبر الأنترنت مع الأطباء في دقائق",
    avoid: "تجنب الإجراءات الإدارية الطويلة",
    avoiddesc: " لتأكيد عملياتك بالخارج",
    consultants: "الاستشاريون",
    consultantsdesc: "سوف يرافقونكم طوال رحلتكم العلاجية.",
    immediate: "تأكيد فوري عبر الانترنت للمواعيد",
    immediatedesc: "استشارات ثانية من أطباء دوليين مرموقين وسهل التواصل معهم من أي مكان في العالم",
    DoctorAndOnlineConsultation: "الاطباء والاستشارة عبر الانترنت",
    LowestToHighstPrice: "من السعر الاقل الي الاعلى",
    HighstToLowestPrice: "من السعر الاعلى الي الاقل",
    PatientsReviews: "تقييمات المرضى",
    Recommended: "مستوى التزكية",
    ConfirmEditAvailability: "هل ترغب في الاحتفاظ بالتواريخ الأولية ام لا؟",
    HospitalInfoEN: "معلومات المستشفى انجليزي",
    HospitalRoomsEN: "غرف المستشفي انجليزي",
    HospitalInfoAR: "معلومات المستشفى عربي",
    HospitalRoomsAR: "غرف المستشفي عربي",
    PaymentSuccess: "تمت عملية الدفع بنجاح",
    PaymentFailed: "عملية الدفع فشلت , حاول مرة اخرى",
    CanNotCompleteFutureSession: "لا يمكن تأكيد الجلسة علي انها مكتملة",
    CanNotCompleteSession: "لا يمكن تأكيد الجلسة علي انها مكتملة,يجب ان يكون التاريخ والوقت منتهي",
    DesAboutUS: "دوكلينيا العلامة التجارية لشركة كيور جورني هي بوابة سياحة طبية مقرها في الإمارات العربية المتحدة و منصة صحية رقمية كاملة ، تحول السياحة العلاجية التقليدية إلى خدمة يمكن الوصول إليها عالميًا.\n يمكن للمرضى حجز موعد عبر الإنترنت ، وإجراء مكالمات فيديو مع أطباء دوليين ، وتحميل صور إصاباتهم ، وتقديم فحوصاتهم ، وفحوصات الدم والتحليل إلى الأطباء المعنيين ، وطلب رأي ثانٍ ، والمتابعة والوصول إلى التدخل الجراحي المقرر في وجهة الأطباء ، أو في وطنهم من خلال زيارات ميدانية لمتخصصين دوليين.\n ستكون هذه شبكة صحية لأي مريض للوصول إلى أطباء مرموقين في الخارج ، دون الحاجة إلى تحمل تكاليف سفر إضافية أو قيود حدود البلدان.",
    Strategic: "الاستراتيجية",

    Signinto: "قم بالتسجيل للحصول على أول مرضاك الدوليين مع دوكلينيا ",
    Part1: ".نحن في دوكلينيا نؤمن بأن الاتحاد هو جوهر القوة والنمو",
    Part2: "إذا كنت من شركات السياحة العلاجية في وجهتك ، مع المستشفيات وعقود الأطباء ، استفد من توزيعنا الواسع ومجموعة المرضى",
    Part3: "كونك أحد المتعاونين معنا سوف يمنحك:",
    Part4: "- الترويج لخدماتك من خلال دوكلينيا",

    Part5: "- حملات تسويقية مخصصة بأدوات الذكاء الاصطناعي للوصول إلى المرضى الدوليين ",
    Part6: "- الانتقال من عملية الحجز التقليدية دون اتصال بالإنترنت إلى منصة رقمية بالكامل",
    Part7: "-  زيادة وعي المرضى بالسياحة العلاجية في وجهتك",
    OurTarget: "نحن نستهدف إحداث ثورة في الخدمات الصحية الرقمية لتكون متاحة للمرضى في جميع أنحاء العالم",
    AboutUs: "معلومات عنا",
    JoinTeam: "انضم لفريقنا",
    P1: "الأشخاص العظماء يصنعون مكانًا رائعًا للعمل",
    P2: "  نحن في دوكلينيا نؤمن بالمثل الدائم الصحة ثروة ، وبالتالي فإننا نسعى جاهدين لتقديم خدمة عالية الجودة لمرضانا في رحلة علاجهم",
    P3: "نحن نعمل في إطار مبتكر في مجال السياحة الدولية العلاجية بهدف واضح لتغيير صناعة الصحة إلى خدمة سهلة ويمكن الوصول إليها لأي مريض ، والتغلب على البيروقراطيات والحدود",
    P4: "الصدق والنزاهة هي قيمنا الأساسية التي نشاركها مع مرضانا والمتعاونين والمساهمين",
    P5: "إذا كنت تعتقد أنك تشترك في نفس القيم ، فأنت متحمس لما تفعله ، انضم إلى فريقنا لتنمو وتضيف تجربة جديدة إلى حياتك المهنية",
    SendUSCV: "أرسل لنا سيرتك الذاتية إلى",
    ToBePart: " لتكون جزءًا من فريق الخدمات الصحية الرقمية المبتكر",
    LetUs: "ودعنا نناقش كيف يمكننا أن ننمو معًا ",
    Beapart: "كن جزءًا من التغيير",
    OurVision: "رؤيتنا",
    Strategicalliances: "التعاونات الاستراتيجية",
    DesVision: "إنشاء شبكة طبية وصحية دولية لخدمة المرضى ، وتحويل صناعة السياحة العلاجية إلى خدمة رقمية يمكن الوصول إليها بالكامل.",
    Ourmission: "مهمتنا",
    DesOurmission: "تتمثل مهمة شركة كيور جورني في تقديم خدمات صحية دولية من خلال مجموعة من الأطباء والمستشفيات البارزين لمرضانا\n طريقة سهلة الوصول وغير مكلفة لطلب آراء ثانية مع الأطباء في الخارج ، أو متابعة علاج المرضى أو الاستعداد لأي علاج أو تدخل جراحي",
    AcceptPaymentText: "نقبل المدفوعات عبر الإنترنت باستخدام",
    AcceptPaymentTextSecond: " بطاقات الائتمان / الخصم فيزا وماستر كارد بالدولار الأمريكي",
    Doctors: "الأطباء",
    Headcompetition: "كن في صدارة المنافسة",
    Bringexpertise: " انقل خبرتك إلى العالم الرقمي",
    Ehealth: "الصحة الإلكترونية هي المستقبل",
    Joining: "عند الانضمام إلى منصة دوكلينيا ، سيكون:",
    phase1: "- التسجيل بدون أي رسوم",
    phase2: "- تلقي الرسوم الخاصة بك في حسابك المصرفي.",
    phase3: "- تقديم الاستشارات عبر الإنترنت لمرضانا حول العالم.",
    phase4: " - تحكم في مواعيدك ألكترونيا.",
    RegNow: "قم بالتسجيل للحصول على أول مرضاك حول العالم مع دوكلينيا ",
    phase5: "- تلقي طلبات العمليات والعلاجات بإجراءات مميكنة.",
    HaveQuestion: "هل لديك أي سؤال؟",
    ListYourHospital: "	ضع اسم المستشفى الخاص بك على دوكلينيا",
    WillContactPatient: "سنقوم بتوصيلك بالمرضى من جميع أنحاء العالم",
    WhyDoclinia: "لماذا دوكلينيا؟",
    DocInfo1: "- تواصل مع مرضى جدد من دول مجلس التعاون الخليجي والشرق الأوسط وشمال افريقيا ",
    DocInfo2: "- افتح قناة توزيع جديدة للمستشفيات الخاصة بك",
    DocInfo3: "- الترويج لتقنيات العمليات الجراحية الجديدة في مناطق أخرى",
    DocInfo4: "-  مدير حساب مخصص للتواصل مع المستشفي",
    DocInfo5: "- السجلات الإكترونية للمرضى",
    DocInfo6: "- متوافق مع القانون العام لحماية البيانات وقانون نقل التأمين الصحي والمسؤولية ",

    CallUSTextUS: "اتصل بنا / راسلنا",
    AddNewSurgeryNote: "يرجى ملاحظة أن هذه الجراحة الجديدة ستتم مراجعتها من قبل فريق المشرف أولاً لإضافتها إلى العمليات الجراحية الخاصة بك",
    FAQ: "اسئلة شائعة",
    HospitalDoctorSpecialities: "أطباء المستشفى والتخصصات",
    ActiveIngredients: "مكونات نشطة",
    TradeName: "اسم تجاري",
    MedicineName: "اسم الدواء",
    NoNoteHasbeenadded: "لم يتم إضافة ملاحظة",
    AddCV: "أضف CV  PDF",
    ServicesProvidedToOurPatients: "الخدمات المقدمة لمرضانا:",
    searchBySpeciality: "بحث بالتخصص",
    searchByDoctor: "بحث بالطبيب",
    accessOurDB: "الوصول إلى قاعدة بياناتنا من الخبراء العالميين المتخصصين والعيادات وعلماء النفس .",
    MeetOurDoctors: "تعرف على أطبائنا",
    MeetOur: "تعرف على",
    HospitalProfile: "ملف المستشفى",
    TopDoctors: "كبار الأطباء",
    With: "مع",
    RateYourExperience: "قيم تجربتك",
    Close: "اغلاق",
    PendingPayments: "دفعات مؤجلة",
    selectAlreadyAddDoc: "يمكنك الاختيار من المستندات المضافة بالفعل او إضافة مستندات جديدة",
    ForPaidSession: "للجلسة المدفوعة",
    downloadNote: "يرجى تنزيل ملف",
    Add: "إضافة",
    Done: "تم",
    Count: "العدد",
    AppointmentPatientConditionThird: "من 72 إلى تاريخ الاستشارة: غير قابل للاسترداد",
    AppointmentPatientConditionSecond: "من 6 أيام إلى 72 ساعة: يتم استرداد 50٪ من المبلغ المدفوع",
    TravelArrange: "إذا كنت بحاجة إلى مساعدة في ترتيبات السفر الخاصة بك",
    AppointmentPatientConditionFirst: "من تاريخ الحجز إلى أسبوع واحد قبل الاستشارة عبر الإنترنت: الإلغاء مجاني",
    sendMail: "يرجى إرسال بريد إلكتروني إلينا إلى",
    cancelSurgeryNote: "يرجى الاتصال بخدمة المرضى لدينا على patient.service@doclinia.com لمتابعة عملية استرداد الإلغاء",
    youhavesuccessfullyreservethispackage: "لقد قمت بحجز هذه الباقة بنجاح",
    Successfully: "بنجاح",
    BirthDay: "تاريخ الميلاد",
    Complete: "تم",
    PleaseAddPrescription: "الرجاء إضافة وصفة طبية",
    saveNote: "الرجاء النقر فوق \"حفظ\" إذا قمت بإجراء أي تغيير",
    EditNote: "  \"الرجاء النقر فوق الزر \"تحرير\" لإدخال ملاحظاتك ثم النقر فوق \"حفظ",
    HospitalDoctor: "دكتور المستشفي",
    HospitalPrice: "سعر المستشفي",
    DoctorPriceSurgery: "سعر الدكتور",
    NoPrescriptionHasbeenadded: "لم يتم إضافة روسته طبية",
    NoDiagnosisHasbeenadded: "لم يتم إضافة التشخيص",
    SurgeryPayRemainNote: "عند تلقي تأكيد من المستشفى بشأن الجراحة ، يمكنك المضي قدمًا في دفع مبلغ التعويض ",
    SurgeryProcessingNote: "من أجل المضي قدمًا في تأكيد الجراحة ، يرجى متابعة دفع رسوم الحجز",
    deposit: "رسوم الحجز",

    DoctorPrice: "سعر كشف الدكتور لهذا الشهر",
    selectSpacificDoctor: "في حال كنت ترغب في إجراء الجراحة مع طبيب معين ، يرجى الاتصال بقسم خدمات المرضى لدينا على:",
    CancellationconditionDescriptionRemain: "  للمضي قدما في استرداد الإلغاء",
    CancellationconditionDescription: " في حالة الإلغاء بسبب رفض التأشيرة أو بسبب ظروف طبية حرجة تمنع المريض من السفر ، سيتم استرداد السعر النهائي للعملية مع خصم رسوم الأعمال الورقية للمريض بناءً على تقديم مستند إثبات. لا تتعلق بإجراءات التأشيرة أو مضاعفات حالة المريض ، يتم إلغاء الجراحة على النحو التالي من 14 إلى 7 أيام حتى تاريخ الجراحة: يتم استرداد 80٪ من إجمالي مبلغ الجراحة من 6 أيام إلى تاريخ الجراحة: 60٪ من يتم استرداد إجمالي مبلغ الجراحة. نحتاج إلى إضافة سبب الإلغاء ، بمجرد أن ينقر المريض على إلغاء الجراحة مع إضافة الملاحظة التالية أسفل مربع السبب: يرجى الاتصال بخدمة المرضى لدينا على",
    PaymentconditionDescriptionFirst: "في حال كان ميعاد العملية من 21 يوما فأكثر, يجب سداد المبلغ المتبقي في غضون 5 أيام عمل من تأكيد المبلغ النهائي من المستشفى",
    PaymentconditionDescriptionSecound: "في حال كان ميعاد العملية خلال العشرين يوم القادمين ، يجب سداد المبلغ المتبقي في غضون يومين عمل من تأكيد المبلغ النهائي للمستشفى",
    PaymentconditionDescriptionThird: "بالنسبة للعمليات الجراحية المحجوزة لمدة 14 يومًا القادمة ، يجب دفع المبلغ المتبقي في غضون يومين عمل من تأكيد المبلغ النهائي للمستشفى وقد يُطلب من المريض إجراؤه مباشرة إلى المستشفى.",
    // CancellationconditionDescriptionFirst: "في حالة الإلغاء بسبب رفض التأشيرة أو بسبب ظروف طبية حرجة تمنع سفر المريض ، فإن استرداد السعر النهائي للجراحة مع خصم رسوم الأعمال الورقية سيتم معالجته للمريض بناءً على تقديم مستند الإثبات.",
    CancellationconditionDescriptionFirst: "فى حالة الالغاء بسبب حالة مرضية حرجة او رفض الفيزا,الرجاء التواصل مع احد وكلاء خدمة المرضى على Patient.service@doclinia.com",
    CancellationconditionDescriptionSecond: "في حالة الإلغاء غير المتعلق بإجراءات التأشيرة أو مضاعفات حالة المريض ، يكون إلغاء الجراحة على النحو التالي:",
    CancellationconditionDescriptionThird: "اذا كان الالغاء من 21 يوم الى 14 يوم قبل ميعاد العملية يسترد العميل 80% من السعر النهائى المتفق عليه مع المستشفى",
    CancellationconditionDescriptionFourth: "اذا كان الالغاء من 14 يوم الى  يوم قبل ميعاد العملية يسترد العميل 50% من السعر النهائى المتفق عليه مع المستشفى",
    PendingRmainSurgeryAmount: "في انتظار مبلغ الجراحة المتبقية",
    DurationByDay: "المدة باليوم",
    RemainingSurgeryAmount: "المبلغ المتبقي للجراحة",
    PayRemainingSurgeryAmount: "دفع مبلغ الجراحة المتبقية",
    PendingProcessFees: "رسوم الحجز المعلقة",
    PricingAvailNote: "الأسعار غير قابلة للتعديل بمجرد حفظها ، يرجى الاتصال بمدير حسابك لمعرفة تغييرات الأسعار",
    MinutsLeft: "الدقائق المتبقية للدفع",
    PassengerNote: "سيتم إرسال سعر السفر من قبل قسم السفر لدينا",
    CantPaylater: "آسف لا يمكنك الدفع لاحقًا",
    GeneratInvoice: "عمل فاتوره",
    EditAvailability: "تعديل المواعيد",
    Canceled: "الغيت",
    ReservationDetail: "تفاصيل الطلب",
    DurationInDay: "المدة باليوم",
    Doosage: "الجرعة",
    MedicineName: "اسم الدواء",
    Medication: "الادوية",

    Allergies: "الحساسية (للأدوية أو الطعام)",
    LastBloodAnalysis: "اخر تاريخ تم فيه عمل تحليل الدم",
    HaveHeartProblem: "هل تعاني من اي امراض في القلب",
    HaveAids: "مريض ايدز",
    AidsDate: "تاريخ المرض من:",

    Ifyeswhen: "إذا كانت الإجابة بنعم ، ادخل التاريخ",
    DiseaseText: "الامراض",
    HaveDisease: "هل عانيت من أي مرض مثل الملاريا والتهاب الكبد وغيرها؟",
    hospitals: "المستشفيات",
    Collaborate: "التعاون",
    PayExtraServices: "دفع الخدمات الإضافية",
    UnPayedExtraService: "خدمة إضافية غير مدفوعة",
    CancelledSurgeries: "الجراحات الملغاة",
    PreferDateFrom: "التاريخ المفضل من",
    PreferDateTo: "التاريخ المفضل الي",
    UpCommingSurgeries: "العمليات الجراحية القادمة",
    OnlinePay: "الدفع عبر الإنترنت",
    NotSetYet: "لم يتم تعيينه بعد",
    DrAdress: "عنوان الطبيب",
    has: "لديه",
    PrescriptionName: "اسم الروشتة",
    HoursLeft: "الساعات المتبقية للدفع",
    DaysLeft: "باقي عدد ايام",
    SurgeryPrice: "سعر العملية",
    payOrPaylaterNote: "يمكنك النقر فوق الدفع الآن للدفع أو الدفع لاحقًا ويمكنك الدفع من الاستشارة عبر الإنترنت من الملف الشخصي.",
    SurgeryPendingDocuments: "العمليات الجراحية والعلاجات",
    YourAllPendingDocuments: " المستندات المعلقة الخاصة بك",
    MedicalReports: "التقارير الطبية",
    CancelledConfirm: "تم إلغاء الحجز بنجاح",
    ExtraServiceTotalPrice: "السعر الإجمالي للخدمة الإضافية",
    PayProcessingFees: "دفع رسوم الحجز",
    PreferDateFrom: "التاريخ المفضل من",
    PreferDateTo: "التاريخ المفضل الي",
    OldSurgeries: "العمليات الجراحية المكتملة",
    OnlinePay: "الدفع عبر الإنترنت",
    NotSetYet: "لم يتم تعيينه بعد",
    HospitalDate: "التاريخ المحدد من المستشفى",
    PrescriptionName: "اسم الروشتة",
    DoctorSpecilties: "تخصصات الدكتور",
    DoctorExperiences: "خبرة الدكتور",
    DoctorLanguages: "لغات الدكتور",

    payOrPaylaterNote1: "الرجاء اختيار واحد من الخيارات التالية:",
    payOrPaylaterNote2: "1. انقر فوق 'تأكيد' لمتابعة الدفع عبر الإنترنت وتأكيد حجزك.",
    payOrPaylaterNote3: "2. انقر على 'ادفع لاحقًا' لدفع الحد الأقصى. خلال الأربع ساعات القادمة. في حالة عدم تنفيذ الدفع ، لن يتم تأكيد حجزك وسيتم تحرير الموعد وإتاحته للمرضى الآخرين.",
    payOrPaylaterNote: "يمكنك النقر فوق الدفع الآن للدفع أو الدفع لاحقًا ويمكنك الدفع من الاستشارة عبر الإنترنت من الملف الشخصي.",
    AppointmentPendingDocuments: "المواعيد عبر الإنترنت",
    MedicalReports: "التقارير الطبية",
    CancelledConfirm: "تم إلغاء الحجز بنجاح",
    cancelledBookings: "المواعيد الملغاه",
    PriceStartingFrom: "السعر يبدا من",
    RequestAppointment: "طلب ميعاد",
    UploadedDocument: "مستندات مرفوعة من قبل",
    Paymentfail: "فشلت عملية الدفع. حاول مرة اخرى",
    PaymentSuccess: "عملية الدفع بنجاح. شكرا على الدفع",
    ProcessingFees: "رسوم الحجز",
    paymentLaterHint: "سيتم حجز الموعد لمدة 4 ساعات فقط. يرجى متابعة الدفع في غضون 4 ساعات القادمة، وإلا سيتم إلغاء الموعد ليكون متاحًا للمرضى الآخرين",
    CantEditSession: "لا يمكن تعديل الحجز قبل 72 ساعة ، يرجى إلغاؤه وإعادة الحجز",
    IsAllowBankTransfare: "مسموح باستخدام التحويلات البنكية",
    AccountNumber: "رقم الحساب",
    BankName: "اسم البنك",
    BranchAddress: "عنوان الفرع",
    NumberOfBeneficiary: "عدد المستفيدين",
    PackageCategory: "نوع الباقة",
    OneSetPackage: "ضبط الباقة",
    Pleaseselectoneofthose: "الرجاء تحديد واحد من هؤلاء",
    FindDoctor: "حدد طبيبك",
    TreatmentsinthisHospital: "العمليات الجراحية",
    JoinOurTeam: "انضم لفريقنا",
    Collaborators: "متعاونون",
    Hospitals: "مستشفيات",
    PatientCare: "رعاية المرضى",
    PATIENTCAREFOOTER: "رعاية المرضى",
    ThreeSetSurgery: "الدفع",
    TwoSetSurgery: "رسوم الدفع",
    OneSetSurgery: "حجز العملية",
    BookingYourSurgery: "حجز عمليتك",
    ShowMore: "عرض المزيد",

    PatientWaiver: "إقرار المريض",
    ContactUs: "اتصل بنا",
    TermsAndConditions: "الأحكام والشروط",
    PrivacyPolicy: "سياسة خاصة",

    YearSummary: "ملخص العام",
    ShowYearSummary: "عرض ملخص العام",
    PleaseTypeHere: "الرجاء الكتابة هنا",
    PleaseSelectATimeFor: "يرجى تحديد وقت",
    PatientSymptomNote: "لم يكتب المريض أي ملاحظة أعراض",
    ReservationHistory: "سجل الحجوزات",
    PatientHistory: "سجل المريض",
    PayLater: "ادفع لاحقا",
    HospitalConfirmNote: "بمجرد التأكيد لا يمكنك التعديل",
    OneSetAppointment: "1. تحديد موعد",
    TwoSetAppointment: "2. أضافة الأعراض",
    ThreeSetAppointment: "3. طريقة الدفع او السداد",
    Book: "حجز",
    Cancellationconditions: "شروط الإلغاء",
    Cancellationconditionspop: "شروط الإلغاء",
    BackAndChangeYourSelection: "رجوع واختر ميعاد اخر",
    Procced: "نفذ",
    NoDataToshow: "لا يوجد بيانات للعرض",
    NoData: "لا يوجد",
    RemainingAmountPaid: "المبلغ المتبقي",
    RecommendationLevel: "مستوى التزكية",
    ProccedOnlineAppointment: "احجز موعدك عبر الإنترنت",
    surgeryCancelConfirm: "هل أنت متأكد من إلغاء الجراحة",
    CancelSurgeryRequest: "طلب إلغاء الجراحة",
    NotSetYet: "لم يحدد بعد",
    AppointmentPatientCondition: "من تاريخ الحجز إلى أسبوع واحد قبل الاستشارة عبر الإنترنت: الإلغاء مجاني من 6 أيام إلى 72 ساعة: يتم رد 50٪ من المبلغ المدفوع من 72 إلى تاريخ الاستشارة: غير قابل للاسترداد",
    CreateNew: "إضافه جديد",
    DoctorProfile: "الملف الشخصي للطبيب",
    PleaseSelectOnOneOfSpecialities: "يرجى تحديد أحد التخصصات*",
    OurPackages: "باقاتنا",
    ChooseSubSpecialty: "اختر التخصص الفرعي",
    ChooseMainSpecialty: "اختر التخصص الرئيسي",
    confirmCancelSessionText1: "انت علي وشك الغاء جلستك",
    confirmCancelSessionText2: "هل انت متأكد؟",
    Packages: "باقات",
    FOLLOWUS: "تابعنا",
    NEWSLETTER: "النشرة الإخبارية",
    FindYourDoctor: "ابحث عن طبيبك",
    Checkuppackages: "أكثر الباقات مبيعًا",
    SetDate: "تعيين التاريخ",
    SessionPrice: "سعر الجلسة",
    New: "جديد",
    editTreatmentSurgery: "يرجى حفظ الجراحة المختارة أولاً",
    Info: "معلومات",
    Edit: "تعديل",
    Treatments: "العلاجات",
    CreateNewTreatment: "إنشاء علاج / جراحة جديدة",
    AddTreatments: "إضافة العلاج / الجراحة",
    EditTreatment: "تحرير العلاج / الجراحة",
    PaymentStatus: "حاله الدفع",
    AssignDoctors: "تعيين الأطباء",
    RequiredDocs: "طلب وثيقة",
    FilterBySurgery: "البحث بالعملية",
    AddAssignDoctorstoSurgeriesTreatments: "اضافة دكاترة للعمليات الجراحية",
    AddHospitalSubSpecialities: "اضافة تخصصات فرعية للمستشفي",
    AddHospitalSpecialities: "اضافة تخصصات المستشفي",
    AboutHospital: " عن المستشفي",
    Paymentconditions: "شروط الدفع",
    HospitalRooms: "غرف المستشفي  ",
    HospitalSpecialities: " تخصصات المستشفي",
    AddAssignDoctorsToSpecialties: "اضافة/اشارة دكاترة لتخصص",
    CreateNewDoctorProfile: "انشاء دكتور جديد",
    FilterBySubSpecialty: "البحث بالتخصصات الفرعية",
    SurgeryDetails: "تفاصيل الجراحة",
    SurgeryTreatment: "الجراحة & العلاج",
    GotoTreatment: "عرض تفاصيل العملية",
    AddSubSpecialities: "اضافة التخصصات الفرعية",
    Specialities: "التخصصات",
    AddSpeciality: "اضافة تخصصات",
    AddTreatments: "اضافة عمليات جراحية",
    SubSpecialities: "التخصصات الفرعية",
    AddSubSpeciality: "اضافة تخصص فرعي",
    FilterBySpecialty: "البحث بالتخصص الاساسي",
    SubSpecialties: "التخصصات الفرعية",
    packages: "الباقات",
    PreferredLanguage: "اللغة المفضلة",
    Clear: "اعادة",
    UserName: "اسم المستخدم",
    HowManyPassengers: "عدد المسافرين",
    PreferredDate: "موعد مفضل",
    ApplyforPackage: " التقدم بطلب للحصول على الباقة",
    ThisPackageincludes: "هذه الباقة تشمل",
    ThisPackagedoesnotinclude: " هذه الباقة لا تشمل",
    Package: "صفقة",
    ChooseDocumentType: "اختر نوع المستند",
    Continueasguest: "استمرار كزائر",
    sendUS: "أرسل لنا استفسارك إلى",
    ServiceProvider: "الخدمات المقدمة لمرضانا:",
    serviceNote2: "الإقامة في الفنادق للمرافقين",
    Transfers: "التحويلات",
    Flights: "الطياران",
    serviceNote1: "ترتيبات التأشيرة مع تعليمات المستندات التي يجب تقديمها لكل وجهة",
    DoctorSessionDetails: "تفاصيل الجلسة",
    DurationInMinutes: "المدة بالدقائق",
    PastDate: "يوم قديم",
    PastDateAlert: "اليوم الذي حددته قد مضى",
    SessionSelected: "الجلسة محجوزة",
    hasReservationSessionon: "لديه مواعد في",
    hasReservationSurgeries: "لديه  عملية",

    Documentdoesntdownloadyet: " وثيقة المريض",
    SessionBlocked: "الجلسة محظورة",
    sessionOldSelected: "لا يمكنك اختيار هذه الجلسة لأنها محجوزة",
    SessionBlockMessage: "لا يمكنك اختيار هذه الجلسة لأنها محظورة",
    SergeryNotify: "إخطار بموعد الجراحة (قبل يومين من تاريخ الجراحة)",
    SergeryPayment: "الدفع مقابل الجراحة مستحق (14 يومًا قبل تاريخ الجراحة)",
    Promotion: "هل ترغب في تلقي العروض والعروض الترويجية",
    InfoHospital: "هل ترغب في تلقي معلومات حول المستشفيات والأطباء الذين تمت إضافتهم مؤخرًا",
    InfoPackage: "هل ترغب في الحصول على معلومات حول باقاتنا",
    NewsMonthely: "هل ترغب في تلقي النشرة الإخبارية الشهرية",
    permissions: "أذونات",
    Join: "انضم",
    ChoiseDate: "اختار تاريخ",
    OnlineReleas: "يجب دفع موعد عبر الإنترنت (قبل ساعة واحدة من إصدار الموعد)",
    YourNotification: "اشعاراتك",
    EditPassword: "تغير كلمة المرور",
    onlineappointmentNotification: "اشعارات المواعيد",
    ContainsLowercase: "حروف صغيرة",
    AddAvailabilityHint: "*فترات المواعيد 30 دقيقة",
    DoctorAddAvailability: "اضافة مواعيد",
    DoctorEditAvailability: "تعديل المواعيد",
    PendingTaskDoctor: "المهام المعلقة",
    AddAvailability: "اضافة",
    AppointmentPatientNoteUpload: "يرجى تحميل التقارير الطبية الخاصة بك ، والمسح الضوئي ، وتحليل الدم أو أي مستندات أخرى متعلقة بالموعد في ملفك الشخصي.",
    AppointmentPatientNotePlease: "يرجى وصف الأعراض للطبيب:",
    AppointmentPatientNote: "تم تأكيد موعدك عبر الإنترنت. ستتلقى بريدًا إلكترونيًا يحتوي على رابط المكالمة عبر الإنترنت.",
    DoctorBlockAvailabilty1: "أنت تحاول حظر نطاق تاريخ / وقت بمواعيد محجوزة عبر الإنترنت.",
    DoctorBlockAvailabilty2: "قم بارسال طلبك إلى مدير حسابك لتعديل المواعيد وإصدار التاريخ والوقت المطلوبين.",
    StartDate: "بداية التاريخ",
    Block: "حظر",
    EndDate: "نهاية التاريخ",
    Sun: "الاحد",
    Mon: "الاثنين",
    Wednes: "الاربعاء",
    Thurs: "الخميس",
    RegisterSuccsessMessage: "شكرا لك على التسجيل في Dawayini. يرجى ادخال بياناتك",
    Fri: "الجمعة",
    Sat: "السبت",
    Tues: "الثلاثاء",
    January: "يناير",
    February: "فبراير",
    Mars: "مارس",
    April: "ابريل",
    May: "مايو",
    June: "يونيو",
    July: "يولية",
    August: "اغسطس",
    September: "سبتمبر",
    October: "اكتوبر",
    November: "نوفمبر",
    December: "ديسمبر",
    From: "من",
    To: "الي",
    DoctorsVisitsDestination: "زيارات الأطباء إلى الوجهة لإجراء العمليات الجراحية والاستشارات في الموقع.",
    SurgeryRequest: " إجراء العمليات الجراحية.",

    ChooseMonthsoptional: "اختر الشهور",
    ChooseDays: "اختر الايام",
    PreviewOnCalendar: "عرض علي التقويم",
    OnlineConsultationPrice: "اخر سعر الاستشارة عبر الإنترنت",
    EditProfile: "تعديل البيانات الشخصية",
    Typeyourbiographyhere: "اكتب سيرتك الذاتية هنا",
    BiographyOptional: "السيرة الذاتية (اختياري)",
    PlaceOfWork: "مكان العمل",
    AddCertificates: "اضافة شهادات",
    LicenseNumber: "رقم الرخصة",
    AdditionalInformation: "معلومات اضافية",
    PrescriptionDetails: "تفاصيل الوصفة الطبية (البيانات أدناه مضمنة في نموذج الوصفة الطبية الخاص بك)",
    PatientDiagnosisNote: "ملاحظات تشخيص المريض",
    Furtherrequestedscansanalysis: "طلبات التحاليل والاشعة",
    DoctorsNotes: "ملاحظات الدكتور",
    ScheduleAnotherAppintment: "تغير الميعاد",
    CopyZoomLink: "نسخ رابط زوم",
    Time: "الوقت",
    Yesbutoccasionallyrarely: "نعم ، ولكن أحيانًا / نادرًا",
    PatientsSymptomsDescription: "وصف أعراض المريض",
    NoDataAvailable: "لا توجد بيانات",
    PatientMedicalReports: "التقارير الطبية للمريض",
    PatientMedicalReportAndRequesteds: " التقارير الطبيه للمريض المطلويه",
    YourMedicalReports: "تقاريرك الطبية",
    PayNow: "ادفع الآن",
    AppointmentPendingPayments: " المدفوعات المعلقة للمواعيد ",
    SurgeriesPendingPayments: " المدفوعات المعلقة للعمليات ",
    YourAllPendingPayments: "  مدفوعاتك المعلقة ",

    OnlineappointmentNeeded: "موعد عبر الإنترنت إذا لزم الأمر",
    MedicalDocumentsNeeded: "المستندات الطبية اللازمه",
    medicalReportsHospitalHeading: "من أجل إرسال التقارير الطبية الخاصة بك إلى المستشفى من أجل تأكيد موعد الجراحة ، يرجى دفع رسوم الحجز. يتم خصم هذه الرسوم من إجمالي سعر الجراحة.",
    RemainingAmountPaidMessage: "يتم دفع المبلغ المتبقي مباشرة للطبيب والمستشفى بعد تأكيد التاريخ و 14 يومًا قبل الجراحة أو العلاج.",
    FamilyName: "اسم العائلة",
    Submit: "حفظ",
    ConfirmedDate: "التاريخ المؤكد",
    Clarificationofeachcriteria6cigarettesadayconsideredasmoderate: "توضيح لكل معيار: 6 سجائر يوميا تعتبر معتدلة",
    HaveChildren: "هل لديك أولاد",
    IsDrinkAlcoholCurrently: "أشرب الكحول حاليا",
    Province: "المحافظة",
    Carparking: "موقف سيارة",
    Facilities: "مرافق",
    maincontact: "جهة الاتصال الرئيسية",
    HospitalGroup: "مجموعة المستشفيات",
    ContactPersonEmail: "البريد الإلكتروني لجهة الاتصال",
    Provinance: "الاصل",
    Accompanyingperson: "الشخص المرافق",
    Forinpatientsanaccompanyingpersonisallowedtostaywiththepatientintheroom: "بالنسبة للمرضى الداخليين ، يُسمح للشخص المرافق بالبقاء مع المريض في الغرفة",
    Prescriptions: "الوصفات الطبية",
    NextOnlineAppointment: "الموعد التالي عبر الإنترنت",
    YouNextOnlineAppointment: "موعدك التالي عبر الإنترنت",

    GraduationDate: "تاريخ التخرج",
    Speciality: "تخصص",
    Pleasecompleteyourregistrationtoaccessourservices: "يرجى إكمال تسجيلك للوصول إلى خدماتنا",
    ReceiveInformationRecentlyAddedHospitalsAndDoctors: "تلقي المعلومات المضافة حديثًا",
    Hospital: "مستشفى",
    Cafeteria: "كافتيريا",
    TV: "تلفزيون",
    HospitalName: "اسم المستشفى",
    HospitalImages: "صور المستشفى",
    ReceiveInformationPackages: "تلقي المعلومات",
    Addcertificates: "أضف الشهادات",
    ReceiveMonthlyNewsletter: "تلقي النشرة الإخبارية الشهرية",
    Additionalinformation: "معلومة اضافية",
    ReceiveOffersAndPromotions: "تلقي العروض والترقيات",
    SurgeryPayment: "موعد دفع جراحة",
    SurgeryDate: "تاريخ الجراحة",
    OnlineAppointmentpayment: "موعد دفع عبر الإنترنت",
    OnlineAppointment: "موعد عبر الإنترنت",
    // Surgery: "العملية الجراحية",
    Surgery: "العمليات الجراحية و العلاجات",
    Price: "السعر",
    SurgeryCategory: "قسم الجراحة",
    ShowHome: "في الصفحة الرئيسية",
    MaritalStatus: " الحالة الإجتماعية",
    MainSpecialty: "التخصص الاساسي",
    ConvertValue: "معامل التحويل",
    Value: " قيمه",
    Percentage: " نسبة",
    AddanotherItem: "إضافة عنصر اخر",
    CodeAR: "الكود بالعربي",
    CodeEN: "الكود بالانجليزي ",
    City: "البلد",
    UserNotificationSetting: "اشعارات المستخدم",
    PaymentPartItem: "اجزاء الدفع",
    SurgeryItems: "عناصر العمليات الجراحية",
    SurgeryCategories: "اقسام العمليات الجراحية",
    Surgeries: "العمليات الجراحية",
    Sponsors: "الممولين",
    Department: "القسم",
    DocumentType: "نوع المستند",
    ReportType: "نوع التقارير",
    AnalysisType: "نوع التحاليل",
    Highpressure: "ضغط مرتفع",
    Lowpressure: "ضغط منخفض",
    ScanType: "نوع الاشعة",
    CommingSoon: "قريبا",
    KinshipType: "نوع القرابة",
    Position: "مكانة",
    SmokeDegree: "درجة التدخين",
    Level: "مستوى",
    Heavy: "ثقيل",
    Moderate: "معتدل",
    Occasionaly: "من حين اخر",
    Doyoudrinkalcohol: "هل تشرب الخمر",
    NoIquit: "No, I quit",
    Next: "التالي",
    Ifyesdescripe: "إذا كانت الإجابة بنعم ، قم بوصف",
    Areyoudiagnosedwithloworhighpressure: "ضغط الدم",
    Areyouasthmatic: "هل انت مصاب بالربو",
    HaveyousufferedfromanydiseasesuchasMalariahepatitisetc: "هل عانيت من أي مرض مثل الملاريا والتهاب الكبد وما إلى ذلك.",
    Prescriptiondetailthebelowdataisincludedinyourmedicalprescriptiontemplate: "تفاصيل الوصفة الطبية (البيانات أدناه مضمنة في طبيتك قالب وصفة طبية)",
    Anycancerdiagnosishistoryinyourfamily: "تم تشخيص أى من أفراد عائلتك بالسرطان",
    DoctorRequestType: "نوع طلب الدكتور",
    Howmanydrinksperday: "كم عدد المشروبات في اليوم",
    drinksperday: " المشروبات في اليوم",
    Haveyougonethroughanysurgerybefore: "هل خضعت لأي عملية جراحية من قبل",
    PaymentStatus: "حالة الدفع",
    PaymentType: "طرق الدفع",
    ExtraServices: "خدمات اضافية",
    PatientServices: "خدمات المريض",
    Extras: "الاضافات",
    Services: "الخدمات",
    Cities: "المدن",
    DocliniaBankTransferDetails: "Doclinia بيانات الحساب البنكي",
    HospitalBankTransferDetails: "بيانات الحساب البنكي للمستشفي",
    SessionPrices: "اسعار الجلسات",
    Links: "الروابط",
    Experiences: "الخبرات",
    Education: "التعليم",
    Characters: "حروف",
    BioEN: "السيرة الذاتية انجليزي",
    BioAR: "السيرة الذاتية عربي ",
    MainFocus: "الهدف الاساسي",
    Country: "البلد",
    Gender: "النوع",
    Widowed: "الأرامل",
    Married: "متزوج",
    male: "ذكر",
    female: "أنثى ",
    TranslationServices: "خدمة الترجمة ",
    TravelArrangementsPatients: "ترتيبات السفر للمرضى.",
    NewslettersHospitalDoctor: "رسائل إخبارية حول المستشفيات والأطباء المدمجة حديثًا ، والتقنيات الجديدة في العمليات الجراحية المعقدة ، والعلاجات الجديدة في بعض الأمراض.",
    UploadDate: "تاريخ الرفع ",
    Locallicensenumber: "رقم الترخيص المحلي",
    Insurancecompanyname: "اسم شركة التأمين",
    Ifyeshowmany: "إذا كانت الإجابة بنعم ، فكم عدد أولادك",
    OtherPhone: "رقم هاتف اخر",
    TitleEN: "اللقب انجليزي",
    ReferanceAreaEN: "المنطقة المرجعية اللغة الإنجليزية",
    ReferanceAreaAR: "المنطقة المرجعية اللغة العربية",
    SessionPrice: "سعر الجلسة",
    PsychologicalTest: "اختبار نفسي",
    Dgree: "درجات",
    Telephone: "تليفون",
    NumberOfViews: "عدد المشاهدات",
    TitleAR: "اللقب عربي",
    PasswordChangedSuccessfully: "تم التغيير بنجاح",
    savequestion: "تم حفظ السؤال ",
    success: "تم التعديل",
    Title: "اللقب",
    Comments: "تعليقات",
    ContainsSpecialCharacter: "تحتوي علي حروف خاصة",
    ContainsUppercase: "تحتوي علي حروف كبيرة",
    ContainsNumber: "تحتوي علي ارقام",
    OldPassword: "الرقم السري القديم",
    NewPassword: "رقم السري الجديد",
    ChangePassword: "تغيير الرقم السري",
    isInTrip: "في رحلة ؟",
    Note: "ملحوظة",
    maxScore: "أقصى درجة",
    DaysOfWeek: "اليوم",
    PickupLocation: "العنوان",
    TIMEOFEND: "تاريخ الانتهاء",
    socialhistory: "التاريخ الاجتماعي",
    Birthdate: "تاريخ الميلاد",
    TIMEOFSTART: "تاريخ البدء",
    BusTrips: "رحلة الاتوبيس",
    BusTrip: "رحلة الاتوبيس",
    companyname: "اسم الشركة:",
    Pickup: "مكان استلام",
    Destination: "الجهة",
    Both: "الكل",
    Content: "المحتوى",
    noanswers: "لا توجد إجابات لهذا السؤال",
    SubjectExamRole: "قواعد الامتحان ",
    Youarealreadyloggedin: "قد قمت بتسجيل الدخول بالفعل!",
    LoginAttempt: "محاولة تسجيل الدخول",
    showNoDataAvailable: "لا توجد بيانات متاحة ... يرجى المحاولة مرة أخرى",
    NoNotesAvailable: "لا توجد ملاحظات",
    showError: "حدث خطأ ما. أعد المحاولة من فضلك",
    RegisterCreatedSuccessfuly: "تم التسجيل بنجاح",
    Update: "تحديث",
    showUpdate: "تم التحديث بنجاح ...",
    SuccessAddNote: "إضافة ملاحظة بنجاح",
    Event: "الحدث",
    Set: "ادراج",
    Change: "تغيير",
    ColorCode: "لون الكود",
    Student: "طالب",
    "Route-Edit": "تعديل-المسار",
    AddEdit: "اضافة/تعديل",
    DescriptionOfAttachment: "وصف الملحقات",
    PleaseInsertYour: "يرجى إدخال ",
    emailaddress: "عنوان بريدك الإلكتروني",
    Single: "أعزب",
    Divorced: "مطلق",
    neverdid: "لم تفعل ذلك ابدا",

    Accepted: "موافقة",
    Refused: "رفض",
    Pending: "قيد الانتظار",
    OnlineAppointmentConfirmed: "تم تأكيد موعدك عبر الإنترنت .",
    OnlineAppointmentConfirmedMessage: " ستتلقى بريدًا إلكترونيًا يحتوي على رابط المكالمه عبر الإنترنت.",
    OnlineAppointmentConfirmedDescribe: " يرجى وصف الأعراض للطبيب:",
    OnlineAppointmentConfirmedRequest: "يرجى تحميل التقارير الطبية الخاصة بك ، والمسح الضوئي ، وتحليل الدم أو أي مستندات أخرى متعلقة بالموعد في ملفك الشخصي.",
    DoctorNotes: "ملاحظات الدكتور",
    Doctorsignature: "توقيع الدكتور",
    StudentChangeRouteRequestAddEdit: "تعديل/اضافة",
    Order: "طلب",
    Yescurrently: "نعم حاليا",
    StudentChangeRouteRequest: "طلب تغير مسار الحافلة",
    AllRoutesAndBusses: "خطوط النقل و الحافلات",
    studentBusOFF: "عدد الطلاب",
    StudentBusOffForWeek: "طلبات الغاء الحافلة ",
    AllChangeRouteRequestAccept: "عدد طلبات تغيير المسار المقبولة",
    AllChangeRouteRequestRefused: "عدد طلبات تغيير المسار المرفوضة",
    AllChangeRouteRequestPending: " عدد طلبات تغيير المسار المعلقة",
    AllChangeRouteRequest: " عدد طلبات تغيير الحافلة",
    UnPaidInstallmentCount: "الاقساط غير المسددة",
    PaidInstallmentCount: " الأقساط المدفوعة",
    AlltInstallmentCounts: "جميع الأقساط",
    EventsForWeek: " أحداث الأسبوع",
    ExamsForWeek: " الامتحانات لمدة أسبوع",
    drivers: "السائقين",
    BankName: "اسم البنك",
    BranchAddress: "عنوان البنك",
    AccountNumber: "رقم الحسابي البنكي",
    IBAN: " رقم الحساب بصيغة IBAN",
    teachers: "المعلمين",
    students: "الطلاب",
    parents: "الاباء",
    registerasanew: "تسجيل جديد",
    AllUsers: " جميع المستخدمين",
    ExamsAndEventsForWeek: " الامتحانات والفعاليات للأسبوع",
    StudentInstallmentCounts: "مجموع أقساط الطلاب",
    Filters: "البحث",
    RelaredAccounts: " الحسابات ذات الصلة",
    Data: " البيانات",
    SendChannelBook: "إرسال مراسلة",
    Send: "ارسال",
    RememberMe: "تذكرنى",
    WelcomeBack: "مرحبًا بك من جديد ، يرجى تسجيل الدخول إلى حسابك.",
    User: "مستخدم",
    StudentTeacherInstallments: "اقساط الطلاب/المستخدمين",
    back: "رجوع",
    Blog: "مقالات",
    CustomUser: "مستخدم معين",
    AllDriver: "كل السائقين",
    AllTeacher: "كل المدرسين",
    AllParent: "كل اولياء الامور",
    Parent: "والي امر",
    True: "نعم",
    yes: "نعم",
    no: "لا",
    medicine: "الدواء",
    False: "لا",
    Active: "فعال",
    Inactive: "موقوف",
    AcceptPatientAbove18Years: "أقر بأن عمري أكبر من 18 عامًا .",
    ExamCode: "كود الامتحان",
    Areyouasmoker: "هل أنت مدخن",
    Pdf: "بي دي إف",
    Video: "فيديو",
    Image: "صورة",
    Document: "ملف",
    UploadDocument: "  تحميل الملف",
    PatientDetails: "تفاصيل المريض",
    Assign: "تعيين",
    showCheckEmail: "إظهار التحقق من البريد الإلكتروني",
    Signup: "تسجيل دخول",
    BackToLogin: "العودة إلى تسجيل الدخول",
    MedicalPackagesSurgeries: "باقة طبية (فحوصات - جراحات تجميلية).",
    PatientsHistoryMedicalService: " يتم عرض تاريخ المرضى لكل مريض مع كل التاريخ الطبي الذي تم إجراؤه من خلال المنصة.",
    Patient: "مريض",
    Logout: "خروج",
    ThisItemAlreadyExist: "هذا البند موجود من قبل",
    AlreadyExist: "موجود من قبل",
    Edit: "تعديل",
    Material: "ملحقات",
    MaterialType: "نوع الماتيريال",
    All: "الكل",
    ClassTeacherSubject: "الفصول",
    AddSubjects: "اضافة مواد",
    Sender: "المرسل",
    Message: "الرسالة",
    MessageType: "نوع الرسالة",
    IsReplyable: "يمكن الرد",
    Recevier: "المرسل اليه",
    OK: "موافق",
    ChannelBook: "المراسلات",
    ChannelBookType: "انواع المراسلات",
    ChannelBookAddEdit: "المراسلات اضافة/ تعديل",
    BusTracking: "تتبع الاتوبيسات",
    ProjectTitle: "دكتورك",
    totalDates: "عدد المواعيد",
    Home: "الرئيسية",
    AddNew: "أضافة",
    UserManagement: "إدارة المستخدمين",
    Action: "العمليات",
    PERIODINMINUTE: "الدقيقة",
    Currency: "عملة",
    SymbolAR: " رمز بالعربي",
    SymbolEN: "  رمز بالانجليزي",
    NameAr: "الاسم بالعربي",
    NameEn: "الاسم بالانجليزي",
    NameFr: "الاسم بالفرانساوي",
    OldDates: "المواعيد المكتمله",
    ReservationStatus: "حالة الحجز",
    Specialty: "تخصص",
    TimeToReadInMinute: "الوقت للقراءة دقيقة",
    AddressAr: " العنوان بالعربي ",
    Address: "العنوان",
    AddressEn: "العنوان بالانجليزي",
    AddressFr: "العنوان بالفرانساوي",
    NewMaterial: "اضافة ملحق",
    ViewMaterial: "الملحقات",
    NoteAr: "ملاحظة بالعربي",
    NoteEn: "ملاحظة بالانجليزي",
    CallLink: "رابط الاتصال",
    NoteFr: "ملاحظة بالفرانساوي",
    StudentUserInstallment: "اقساط الطلاب/المستخدمين",
    AboutUsAr: " معلومات عنا بالعربي",
    AboutUsEn: " معلومات عنا بالانجليزي",
    AboutUsFr: " معلومات عنا بالفرانساوي",
    Logo: "لوجو",
    SendVertificationCode: "ارسال رمز التعريف",
    sendcode: "ارسال الرمز",
    code: "الرمز",
    Cancel: "الغاء",
    DoctorComment: "تعليق الطبيب",
    Delete: "حذف",
    TOTALPRICE: "السعر الكلي",
    PRICE: "السعر",
    HospitalInfo: "معلومات المستشفى",
    Discount: "خصم",
    DoctorSession: "جلسة طبيب",
    Doctors: "الأطباء",
    PhoneNumber: "رقم الهاتف",
    ContactPerson: "الشخص الذي يمكن الاتصال به",
    VertificationCode: "رمز التعريف",
    Vertify: "تاكيد الرمز",
    MainInfo: "البيانات الاساسية",
    LoginInfo: "بيانات الدخول",
    Save: "حفظ",
    DateFrom: "التاريخ من",
    DateTo: "تاريخ الي",
    TimeFrom: "الوقت من",
    TimeTo: "الوقت الي",
    FutureSurgeries: "العمليات القادمه",
    SearchDoctors: "ابحث عن الأطباء",
    advantageschoose: "لماذا نحن",
    Email: "البريد الاكتروني",
    Password: "الرقم السري",
    Buses: "الاتوبيسات",
    view: "عرض",
    Bus: "اتوبيس",
    Attachment: "الملحقات",
    Installments: "الأقساط",
    Installment: "ألقسط",
    Stage: "المرحلة",
    StageYear: "السنة",
    Class: "الفصل",
    Age: "السن",
    OnlineAppointmentJoinAlert: "أنت منسق المكالمة التي تنتهي تلقائيًا بعد 40 دقيقة. يرجى منك الانضمام في الوقت المحدد.",
    Internationalinsurance: "تأمين دولي",
    Subjects: "المواد",
    NewAttachment: "ملحق جديد",
    AddAttachment: "اضافة ملحق",
    viewsurgery: "عرض العملية",

    Description: "الوصف",
    URL: "الرابط",
    File: "الملف",
    SeeAllPrescriptions: " جميع الروشتات ",
    NewBus: "اضافة اتوبيس",
    AddBus: "اضافة اتوبيس",
    ModelAr: "النموذج بالعربي",
    ModelEn: "النموذج بالانجليزي",
    ModelFr: "النموذج بالفرنساوي",
    IsActive: "فعال",
    FollowAppointmentsConsultations: "متابعة المواعيد بعد الاستشارات.",
    InstallmentTypeEn: "اسم نوع القسط بالعربي",
    AddInstallment: "أضافة قسط",
    NewInstallment: "قسط جديد",
    PayDate: "تاريخ الدفع",
    IsPaid: "الدفع",
    Routes: "الطرق",
    Users: "المستخدمين",
    Students: "الطلاب",
    Doctor: "طبيب",
    AlDoctor: "الطبيب",
    signinasa: "تسجيل الدخول ك",
    Reservation: "حجز",
    Prescription: "روشتة",
    Reservationedit: "تعديل الحجز",
    FeesTo: "روسم",
    SessionDurationInMinute: "مدة الجلسة بالدقيقة",
    OnlineAppointmentsOnlineConsultations: "المواعيد عبر الإنترنت للاستشارات عبر الإنترنت",
    Teachers: "المعلمين",
    Drivers: "السائقين",
    ExaminationManagement: "أدارة الامتحانات",
    QuestionBank: "بنك الاسئلة",
    ExamRule: "قواعد الامتحان",
    ExamGeneration: "انشاء الامتحان",
    Setting: "الاعدادات",
    SchoolInfo: "بيانات المدرسة",
    AssessmentType: "نوع التقييم",
    Rate: "التقييم",
    FeesFrom: "الرسوم من",
    BusSuperVisors: "مشرفين الاتوبيسات",
    Language: "اللغة",
    GradeType: "نوع الدرجة",
    ExamType: "نوع الامتحان",
    QuestionLevel: "مستوي السؤال",
    QuestionType: "نوع السؤال",
    Stages: "المراحل الدراسية",
    StageYears: "سنين المراحل الدراسية",
    InstallmentType: "نوع القسط",
    Name: "الاسم",
    FullName: " الاسم الكامل ",
    FamliName: "اسم العائلة",
    FirstName: "الاسم الأول",

    Reset: "اعادة",
    UserWithSamePhoneNumberExist: "يوجد مستخدم بنفس رقم الهاتف",
    SomethingWentWrong: "حدث خطأ ما",
    CodeIsNotExist: "رمز التعريف خطأ",
    Welcome: "مرحبا بك",
    Date: "التاريخ",
    AvailableDateFrom: "التاريخ المتاح من",
    AvailableDateTo: "التاريخ المتاح الي",

    ClassSessionName: "الحصة",
    SubjectName: "المادة",
    FromDate: "من",
    ToDate: "الي",
    ClassSession: "الحصة",
    ClassSchedule: "جدول الحصص",
    ClassScheduleAddEdit: "جدول الحصص اضافة/ تعديل",
    Status: "الحالة",
    Modules: "النماذج",
    Newlanguage: "لغة جديدة",
    AddLanguage: "اضافة لغة",
    NewModule: "وحدة جديد",
    AddModule: "اضافة وحدة",
    Module: "الوحدة",
    Route: "الطريق",
    Details: "التفاصيل",
    AddRouteDetails: "اضافة تفاصيل الطريق",
    Longitude: "خط الطول",
    Latitude: "خط العرض",
    Region: "المنطقة",
    NewRouteDetails: "تقاصيل طريق جديدة",
    showPasswordChanged: "عرض تغيير كلمة المرور",
    Model: "النموذج",
    BusSupervisor: "مشرف الاتوبيس",
    Driver: "السائق",
    DriverAddEdit: "السائق اضافة/ تعديل",
    AddDriver: "اضافة سائق",
    NewDriver: "سائق جديد",
    Classes: "الفصول",
    NewSubject: "مادة جديدة",
    Subject: "المادة",
    Teacher: "المعلم",
    NewClass: "فصل جديد",

    ServicesprovidedToPatients: "الخدمات المقدمة لمرضانا:",
    TeacherAddEdit: "المعلم اضافة/ تعديل",
    AddComment: "أضف تعليق",
    CommentEN: "التعليق باللغة الإنجليزية",
    CommentAR: "التعليق باللغة العربيه",
    NumOfChildren: "عدد الاطفال",
    DiseaseNote: "ملاحظة المرض",
    CancerHistoryinFamilyNote: "سجل السرطان في ملاحظة الأسرة",
    AddSubject: "اضافة مادة",
    SubjectMaxScore: "درجة المادة",
    StudentExam: "امتحانات الطالب",
    QuestionNum: "عدد الاسئلة",
    QuestionAr: "السؤال بالعربي",
    QuestionEn: "السؤال بالانجليزي",
    QuestionFr: "السؤال بالفرانساوي",
    NewQuestion: " سؤال جديد",
    Question: "السؤال",
    PatientName: "اسم المريض",
    OtherCompany: "الشركات الاخري",
    Answers: "الاجابات",
    AnswerAr: "الاجابة بالعربي",
    AnswerEn: "الاجابة بالنجليزي",
    AnswerFr: "الاجابة بالفرانساوي",
    IsRight: "صحيح",
    // icon:"أيقو  نة",
    Addanswer: "اضافة اجابة",
    RecommendationEN: "إعادة الأمر باللغة الإنجليزية",
    RecommendationAR: "إعادة الأمر باللغة العربية",
    RequestedFrom: "مطلوبه من",
    DescriptionAr: "الوصف بالعربي",
    DescriptionEn: "الوصف بالانجليزي",
    DescriptionFr: "الوصف بالفرانساوي",
    Download: "تحميل",
    Type: "النوع",
    AddRule: "اضافة قاعدة",
    NewRule: "قاعدة جديدة",
    RuleInfo: "بيانات القاعدة",
    DoctorName: "اسم االدكتور ",
    ExamStartDate: "تاريخ الامتحان",
    Exams: "الامتحانات",
    TimeOfExamInMinutes: "مدة الامتحان بالدقائق",
    Generate: "انشاء",
    ExamMainInfo: "بيانات الامتحان",
    GenerateQuestions: "انشاء الاسئلة",
    Text: "مقالي",
    TrueFalse: "صح او خطأ",
    MultiChoose: "متعدد الاختيارات",
    TimeInMinute: "المدة بالدقائق",
    Weight: "الدرجة",
    TypeAr: "النوع بالعربي",
    TypeEn: "النوع بالانجليزي",
    TypeFr: "النوع بالفرانساوي",
    UPDATE: "تعديل",
    Amount: "المبلغ",
    InstallmentDate: "تاريخ القسط",
    DeadLine: "الموعد النهائي",
    LevelAr: "المستوي بالعربي",
    LevelEn: "المستوي بالانجليزي",
    LevelFr: "المستوي بالفرانساوي",
    EventType: "نوع الحدث",
    Events: "الاحداث",
    SchoolArchitecture: "اساسيات المدرسة",
    UserType: "نوع المستخدم",
    RelatedAccounts: "الحسابات المرتبطة",
    UserManagment: "ادارة المستخدمين",
    SubjectManagment: "ادارة المواد",
    StageYearSubjects: "مواد المرحلة الدراسية",
    SubjectModules: "ملحقات المادة",
    BuseManagment: "ادارة الاتوبيسات",
    EventManagment: "ادارة الاحداث",
    InstallmentManagment: "ادارة الاقساط",
    Grade: "الدرجة",
    GRADEFROM: "الدرجة من",

    GradeTo: "الدرجة الي",

    Success: "نجاح",
    CreatedSuccessfully: "تم الاضافة بنجاح",
    UpdatedSuccessfully: "تم التعديل بنجاح",
    Error: "خطا",
    CreatedFailed: "لم يتم الاضافة",
    UpdatedFailed: "لم يتم التعديل",
    DeletedSuccessfully: "تم الحذف بنجاح",
    DeleteFailed: "لم يتم الحذف",
    Confirm: "تاكيد",
    Confirmpassword: "تأكيد كلمة المرور",
    DeleteConfirmText: "هل انت متاكد من انك تريد الحذف",
    ErrorTryLater: "حث خطا ما برجاء المحاولة في وقت لاحق",
    Dashboard: "احصائيات",
    eCommerce: "احصائيات",
    StudentExamAnswer: "اجابات الطالب",
    UpdateImage: "اضافة الصورة",
    RemoveImage: "حذف الصورة",
    AppointmentDate: "تاريخ الحجز",
    PendingMedicalDocuments: "المستندات الطبية المعلقة",

    PendingMedicalReports: "التقارير الطبية المعلقة",
    SurgeryAndTreatment: "الجراحة والعلاج",
    MoreDetails: "المزيد",
    surfaceDescribtion: "سيرشدك مستشارونا خلال رحلة العلاج",
    dollarDescribtion: "تجنب الإجراءات الإدارية الطويلة لتأكيد العمليات الجراحية في الخارج",
    clockDescribtion: "تأكيد فوري على الإنترنت لمواعيد أطبائك. آراء ثانية مع أطباء دوليين مرموقين يمكن الوصول إليها من أي مكان في العالم",
    HairPackageDescribtion: "باقات علاج الشعر",
    EnglishLangTitle: "English",
    ArabicLangTitle: "العربية",
    DoctorSearch: "بحث عن دكتور",
    TreatmentsProfile: "تفاصيل العلاج",
    DoctorProfileData: "الملف الشخصي للدكتور",
    Specialties: "التخصصات",
    AddSpecialties: "اضافة تخصصات",
    AddDoctors: "اضافة دكتور",
    ManagePayments: "ادارة المدفوعات",
    withDoctor: "مع دكتور",
    STEPONE: "اولا :",
    STEPTWO: "ثانيا :",
    STEPTREE: "ثالثا : ",
    PaymentInformation: "معلومات الدفع",
    ReservationSession: "معلومات حجز الجلسة",
    PaymentInfo: "معلومات الدفع",
    PendingNote: "يتم حظر الموعد خلال الساعات الأربعة القادمة . في حالة عدم الدفع سيتم اتاحة الميعاد للمرضى الاخرين",
    AppointmantBookingReservationDetails: "تفاصيل موعد حجزك",
    SurgeryBookingReservationDetails: "تفاصيل موعد العملية",
    ResevrationSessionDetails: "تفاصيل الجلسة",
    CancelBookingAppointmentMessage: "يرجى الملاحظة: لقد ألغيت حجزك الحالي بالفعل ، سيقوم النظام بإزالة تفاصيل الحجز الحالية الخاصة بك شكرا لاستخدام خدمات docLinia نحن نتطلع لرؤيتك قريبا        ",
    ConfirmNote: "تم استلام دفعتك وتأكيدها!",
    PatientInfo: "معلومات العميل",
    PreviewHistory: "سجل الحجوزات السابقة",
    SessionID: "كود الجلسة",
    PleaseNote: "برجاء العلم",
    ReservationSurgrey: "معلومات حجز العملية",
    SurgeryID: "كود العملية",
    SurgeryName: "اسم العملية",
    DocumentName: "اسم الوثائق",
    BookingSurgeryTitle: "حجز العمليات الجراحية & العلاجات",
    DoctorSameSpecialty: "الدكتور في هذا التخصص",
    profileLink: "الصفحة الشخصية",
    BookTheSurgery: "حجز العملية",
    Hosptial: "مستشفي",
    DrTitle: "د.",
    AppointmentPatientConditionTitle: "شروط الالغاء",
    TermsAndCondetions: "الشروط",

    //  Image:"صوره",
    Apps: "Apps",
    Todo: "Machen",
    Chat: "Plaudern",
    Calendar: "Kalender",
    FullCalendar: "Calendrier completVollständiger Kalender",
    SimpleCalendar: "Einfacher Kalender",
    Shop: "Geschäft",
    Cart: "Wagen",
    WishList: "Wunschzettel",
    Checkout: "Auschecken",
    UI: "UI",
    DataList: "Datenliste",
    ListView: "Listenansicht",
    ThumbView: "Daumenansicht",
    Grid: "Gitter",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Farben",
    Card: "Karte",
    Basic: "Basic",
    Statistics: "Statistiken",
    Analytics: "Analytics",
    CardActions: "Kartenaktionen",
    CardColors: "Kartenfarben",
    Table: "Tabelle",
    agGridTable: "agGrid-Tabelle",
    Components: "Komponenten",
    Alert: "Warnen",
    Avatar: "Benutzerbild",
    Badge: "Abzeichen",
    Breadcrumb: "Breadcrumb",
    Button: "Taste",
    ButtonGroup: "Schaltflächengruppe",
    Chip: "Chip",
    Collapse: "Zusammenbruch",
    Dialogs: "Dialoge",
    Divider: "Teiler",
    DropDown: "Dropdown-Liste",
    List: "Liste",
    Loading: "Wird geladen",
    Navbar: "Navbar",
    Notifications: "Benachrichtigungen",
    Pagination: "Seitennummerierung",
    Popup: "Pop-up",
    Progress: "Fortschritt",
    Sidebar: "Seitenleiste",
    Slider: "Schieberegler",
    Tabs: "Tabs",
    Tooltip: "QuickInfo",
    Upload: "Hochladen",
    FormsAndTable: "Formulare und Tabelle",
    FormElements: "Formularelemente",
    Select: "Wählen",
    Switch: "Schalter",
    Checkbox: "Ankreuzfeld",
    Radio: "Radio",
    Input: "Eingang",
    NumberInput: "Nummer eingeben",
    Textarea: "Textbereich",
    FormLayouts: "Formularlayouts",
    FormWizard: "Formzauberer",
    FormValidation: "Formularvalidierung",
    FormInputGroup: "Formulareingabegruppe",
    Pages: "Seiten",
    Authentication: "Authentifizierung",
    Login: "دخول",
    Register: "نسجيل",
    ForgotPassword: "نسيت كلمة المرور؟",
    ResetPassword: "اعادة تعيين كلمة المرور",
    LockScreen: "Bildschirm sperren",
    Miscellaneous: "Verschiedenes",
    ComingSoon: "Demnächst",
    404: "404",
    500: "500",
    NotAuthorized: "Nicht berechtigt",
    Maintenance: "Instandhaltung",
    Profile: "صفحتي",
    Invoice: "Rechnung",

    Search: "بحث",
    KnowledgeBase: "Wissensbasis",
    ChartsAndMaps: "Diagramme und Karten",
    Charts: "Diagramme",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Karte",
    Extensions: "Erweiterungen",
    QuillEditor: "Quill-Editor",
    DragAndDrop: "Ziehen und loslassen",
    Datepicker: "Datumsauswahl",
    DatetimePicker: "Datum / Uhrzeit-Auswahl",
    AccessControl: "Zugangskontrolle",
    I18n: "I18n",
    Carousel: "Karussell",
    Clipboard: "Zwischenablage",
    ContextMenu: "Kontextmenü",
    StarRatings: "Sterne Bewertungen",
    Autocomplete: "Autovervollständigung",
    Tree: "Baum",
    Import: "Einführen",
    Export: "Export",
    ExportSelected: "Auswahl exportieren",
    Others: "Andere",
    MenuLevels: "Menüebenen",
    MenuLevel2p1: "Menüebene 2.1",
    MenuLevel2p2: "Menüebene 2.2",
    MenuLevel3p1: "Menüebene 3.1",
    MenuLevel3p2: "Menüebene 3.2",
    DisabledMenu: "Deaktiviertes Menü",
    Support: "Unterstützung",
    Documentation: "Dokumentation",
    RaiseSupport: "Unterstützung erheben",
    demoTitle: "Kartentitel",
    demoText: "Kuchen Sesam Schnaps Cupcake Lebkuchen Dänisch Ich liebe Lebkuchen. Apfelkuchen-Torte-Jujuben chupa chups muffin halvah Lutscher. Schokoladenkuchenhaferkuchen Tiramisu-Marzipanzuckerpflaume. Donut süße Kuchen Hafer Kuchen Dragée Obstkuchen Zuckerwatte Zitrone Tropfen.",
    TermsOfUse: "Gebruiks voor waarden",
    Imprints: "Opdrukken",
    MedicalHistory: "السجل الطبي",
    History: "السجل ",
    PatientMedicalHistory: "السجل الطبي للمريض",
    UpdateMedicalHistory: "تحديث السجل الطبي",
    YourMedicalHistory: "سجلك الطبي",
    Electornicprescription: " الروشتة الطبيه",
    PrintYourPrescription: "طبع الروشتة",
    OnlineConsultations: "الاستشارات عبر الموقع ",
    YourOnlineConsultations: "استشاراتك عبر الموقع",
    RequestOnlineConsultations: "طلب استشارة",
    PendingTask: "المهام المعلقة",
    PendingDocument: "الوثائق المعلقة",
    Payments: "المدفوعات",
    Repeat: "تكرار",
    SuccsessMessage: " تم العملية بنجاح",
    RepeatMedicalOnlineConsultations: "كرر الاستشارات الطبية عبر الموقع",
    SurgeriesTreatments: "العمليات الجراحية & العلاجات",
    ShortAccess: "الوصول الموجز",
    YourPatients: " المرضي ",
    addYourAvailability: "اضافة جدولة",
    editYourAvailability: "تعديل الجدولة",
    thisWeekOnlineAppointments: "مواعيد الاسبوع الحالي",
    travelArrangmentDiloag: "من فضلك ارسال لنا بريد الالكتروني علي travel@doclinia.com",
    travelArrangmentDiloagPlus: "لترتيب متطلبات السفر الخاصة بك",
    appointmentPaymentAlert: "سيتم حظر الموعد بالنسبة لك لمدة 4 ساعات ثم يتم تحريره ،",
    appointmentPaymentAlertPlus: "لذا يرجى إتمام الدفع في أقرب وقت ممكن",
    DownloadPrescription: " تحميل الروشتة",
    PatientDiagnosis: " تشخيص المريض",
    Dossage: "الجرعة",
    Duration: "المدة بالأيام",
    SearchBy: "بحث بواسطة",
    Or: "أو",
    AllAddedTreatments: "جميع العمليات المضافة",
    AllAddedDoctors: "جميع الاطباء المضافة",
    YourAllAppointmentConsulataion: "استشاراتك المحجوزة",
    YourAllSurgeriesAndTreatment: "العمليات الجراحية والعلاجات المحجوزة",
    UpCommingDates: "المواعيد القادمة",
    Dr: "د",
    Certificates: "الشهادات",
    ChooseYear: "اختار السنه",
    Biography: "سيرة ذاتية",
    SelectATimeFor: "حدد وقتا",
    NextAppointment: " الميعاد القادم",
    onlineAppointmentSpecificDateMessage: "إذا كنت ترغب في حجز موعد عبر الإنترنت في تاريخ محدد، برجال التواصل معنا علي ",
    //onlineAppointmentSpecificDateMessage: "في حالة رغبتك في تحديد موعد عبر الإنترنت في هذا التاريخ المحدد وعلى الرغم من عدم توفر الإنترنت ، يرجى الاتصال بوكلاء خدمة المرضى لدينا عبر البريد الإلكتروني:",
    CardHolder: "حامل البطاقة",
    CardNumber: "رقم البطاقة",
    CVV: "رقم الكود",
    ExpiryDate: "تاريخ الانتهاء",
    TestsAndReports: "الاختبارات و التقارير",
    SpecialistDelectedTopDestination: "متخصص لدينا في أفضل وجهة مختارة",
    TopDestinations: "أشهر الواجهات",
    DoctorRecordWithId: "سجل الطبيب مع معرف",
    NotFound: "لا يوجد",
    SearchPatient: "بحث المريض",
    Accommodation: "الاقامة",
    PatientDiagnosisPrescription: "تشخيص المريض والروشته",
    PrescriptionDetailsTitle: "تفاصيل الوصفة الطبية (البيانات أدناه مضمنة في طبيتك قالب وصفة طبية)",
    BusStation: "محطة الاتوبيس",


    Toilets: "مراحيض",
    AcceptTermsConditions: "أوافق على الشروط والأحكام.",
    CreateAccount: " أنشاء حساب",
    ChooseAccountType: " الرجاء اختيار نوع الحساب",
    AdditionalRequestedDocuments: "المستندات المطلوبة الإضافية",
    PaymentDateAccount: "تاريخ الدفع والحساب المصرفي",
    SurgeryTreatmentIncludes: "العلاج / الجراحة يشمل",
    SurgeryTreatmentNotIncludes: " العلاج / الجراحة لا يشمل",
    MedicalDocumentation: "المستندات الطبية",
    TravelArrangements: "ترتيبات السفر",
    PatientSymptoms: "اعراض المريض",
    Top: "أفضل",
    Destinations: "الوجهات",
    Explore: "المزيد",
    OurPartners: "شركاؤنا",
    DrInfo: "معلومات الدكتور",
    SessionDate: "تاريخ الجلسة",
    ReservitionDate: "تاريخ الحجز",
    DownloadBooking: "تحميل الحجز",
    PRIVACYPOLICY: "سياسة الخصوصية",
    COLLABORATEFotter: "التعاون",
  },
  fr: {
    showNoDataAvailable: "Aucune donnée disponible ... Veuillez réessayer",
    NoNotesAvailable: "Aucune note disponible",
    SorryYouAccountisNotActive: "Désolé .. Votre compte n'est pas actif",
    WelcomeMsg: "Bienvenue à nouveau, veuillez vous connecter à votre compte.",
    Update: "Mettre à jour",
    showError: "Une erreur s'est produite ... Veuillez réessayer",
    showUpdate: "Enregistrement mis à jour avec succès ...",
    showCheckEmail: "Veuillez vérifier votre e-mail pour un nouveau mot de passe ...",
    showPasswordChanged: "Mot de passe modifié ...",
    DescriptionOfAttachment: "Description Of Attachment",
    LoginAttempt: "Login Attempt",
    Youarealreadyloggedin: "You are already logged in!",
    ModelAr: "Modèle arabe",
    Password: "Mot de passe",
    RememberMe: "Souviens-toi de moi",
    WelcomeBack: "Bienvenue à nouveau, veuillez vous connecter à votre compte.",
    Dashboard: "Tableau de bord",
    eCommerce: "commerce électronique",
    Apps: "Applications",
    Todo: "Faire",
    Chat: "Bavarder",
    Calendar: "Calendrier",
    FullCalendar: "Calendrier complet",
    SimpleCalendar: "Calendrier simple",
    Shop: "Boutique",
    Cart: "Chariot",
    WishList: "Liste de souhaits",
    Checkout: "Check-out",
    UI: "UI",
    DataList: "Liste de données",
    ListView: "Voir la liste",
    ThumbView: "Thumb View",
    Grid: "la grille",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Couleurs",
    Card: "Carte",
    Basic: "De base",
    Statistics: "Statistiques",
    Analytics: "Analytique",
    CardActions: "Actions de carte",
    CardColors: "Couleurs de la carte",
    Table: "Table",
    agGridTable: "tableau agGrid",
    Components: "Composants",
    Alert: "Alerte",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Bouton",
    ButtonGroup: "Groupe de boutons",
    Chip: "Puce",
    Collapse: "Effondrer",
    Dialogs: "Dialogues",
    Divider: "Diviseur",
    DropDown: "Menu déroulant",
    List: "liste",
    Loading: "Chargement",
    Navbar: "Barre de navigation",
    Notifications: "Les notifications",
    Pagination: "Pagination",
    Popup: "Apparaitre",
    Progress: "Le progrès",
    Sidebar: "Barre latérale",
    Slider: "Curseur",
    Tabs: "Onglets",
    Tooltip: "Info-bulle",
    Upload: "Télécharger",
    FormsAndTable: "Formulaires et tableau",
    FormElements: "Éléments de formulaire",
    Select: "Sélectionner",
    Switch: "Commutateur",
    Checkbox: "Case à cocher",
    Radio: "Radio",
    Input: "Contribution",
    NumberInput: "Nombre d'entrée",
    Textarea: "Textarea",
    FormLayouts: "Dispositions de formulaire",
    FormWizard: "Assistant de formulaire",
    FormValidation: "Validation du formulaire",
    FormInputGroup: "Groupe de saisie de formulaire",
    Pages: "Pages",
    Authentication: "Authentification",
    Login: "S'identifier",
    Register: "registre",
    ForgotPassword: "Mot de passe oublié",
    ResetPassword: "réinitialiser le mot de passe",
    LockScreen: "Écran verrouillé",
    Miscellaneous: "Divers",
    ComingSoon: "Arrive bientôt",
    Error: "Erreur",
    404: "404",
    500: "500",
    NotAuthorized: "Pas autorisé",
    Maintenance: "Entretien",
    Profile: "Profil",
    Invoice: "Facture d'achat",

    Search: "Chercher",
    KnowledgeBase: "Base de connaissances",
    ChartsAndMaps: "Graphiques Et Cartes",
    Charts: "Graphiques",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Carte",
    Extensions: "Extensions",
    QuillEditor: "Éditeur de piquants",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Sélecteur de date",
    DatetimePicker: "Sélecteur de date / heure",
    AccessControl: `Contrôle d'accès`,
    I18n: "I18n",
    Carousel: "Carrousel",
    Clipboard: "Presse-papiers",
    ContextMenu: "Menu contextuel",
    StarRatings: "Classement par étoiles",
    Autocomplete: "Terminé automatiquement",
    Tree: "Arbre",
    Import: "Importation",
    Export: "Exportation",
    ExportSelected: "Exporter sélectionné",
    Others: "Autre",
    MenuLevels: "Niveaux de menu",
    MenuLevel2p1: "Niveau de menu 2.1",
    MenuLevel2p2: "Niveau de menu 2.2",
    MenuLevel3p1: "Niveau de menu 3.1",
    MenuLevel3p2: "Niveau de menu 3.2",
    DisabledMenu: "Menu désactivé",
    Support: "Soutien",
    Documentation: "Documentation",
    RaiseSupport: "Augmenter le soutien",
    demoTitle: "Titre de la carte",
    demoText: `Le gâteau au sésame s'enclenche au petit pain au pain d'épices danois J'adore le pain d'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.`,
    TermsOfUse: "Conditions d'utilisation",
    PrivacyPolicy: "Politique de confidentialité	",
    Imprints: "Empreintes"
  },
  pt: {
    DescriptionOfAttachment: "Description Of Attachment",
    Dashboard: "painel de controle",
    eCommerce: "comércio eletrônico",
    Apps: "APPS",
    Todo: "Façam",
    Chat: "Bate-papo",
    Email: "O email",
    Calendar: "Calendário",
    FullCalendar: "Calendário Completo",
    SimpleCalendar: "Calendário Simples",
    Shop: "fazer compras",
    Cart: "Carrinho",
    WishList: "Lista de Desejos",
    Checkout: "Confira",
    UI: "UI",
    DataList: "Lista de dados",
    ListView: "Exibição de lista",
    ThumbView: "Thumb View",
    Grid: "Grade",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Cores",
    Card: "Cartão",
    Basic: "Basic",
    Statistics: "Estatisticas",
    Analytics: "Analytics",
    CardActions: "Ações do Cartão",
    CardColors: "Cores do cartão",
    Table: "Mesa",
    agGridTable: "Tabela AgGrid",
    Components: "Componentes",
    Alert: "Alerta",
    Avatar: "Avatar",
    Badge: "Crachá",
    Breadcrumb: "Breadcrumb",
    Button: "Botão",
    ButtonGroup: "Grupo de botões",
    Chip: "Lasca",
    Collapse: "Colapso",
    Dialogs: "Diálogos",
    Divider: "Divisor",
    DropDown: "Suspenso",
    List: "Lista",
    Loading: "Carregando",
    Navbar: "Navbar",
    Notifications: "Notificações",
    Pagination: "Paginação",
    Popup: "Aparecer",
    Progress: "Progresso",
    Sidebar: "Barra Lateral",
    Slider: "Controle Deslizante",
    Tabs: "Guias",
    Tooltip: "dica de ferramenta",
    Upload: "Envio",
    FormsAndTable: "Formulários e tabela",
    FormElements: "Elementos do formulário",
    Select: "Selecione",
    Switch: "Interruptor",
    Checkbox: "Caixa de seleção",
    Radio: "Rádio",
    Input: "Entrada",
    NumberInput: "Entrada numérica",
    Textarea: "Textarea",
    FormLayouts: "Layouts de formulário",
    FormWizard: "Assistente de Formulário",
    FormValidation: "Validação de Formulário",
    FormInputGroup: "Grupo de entrada de formulário",
    Pages: "PÁGINAS",
    Authentication: "Autenticação",
    Login: "Entrar",
    Register: "Registo",

    ForgotPassword: "Esqueceu A Senha",
    ResetPassword: "Redefinir Senha",
    LockScreen: "Tela de bloqueio",
    Miscellaneous: "Diversos",
    ComingSoon: "Em breve",
    Error: "Erro",
    404: "404",
    500: "500",
    NotAuthorized: "Não autorizado",
    Maintenance: "Manutenção",
    Profile: "Perfil",
    Invoice: "Fatura",

    Search: "Procurar",
    KnowledgeBase: "Base de Conhecimento",
    ChartsAndMaps: "Gráficos E Mapas",
    Charts: "Gráficos",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Mapa",
    Extensions: "Extensão",
    QuillEditor: "Quill-Editor",
    DragAndDrop: "Arraste e solte",
    Datepicker: "Datepicker",
    DatetimePicker: "Seletor de data e hora",
    AccessControl: "Controle de acesso",
    I18n: "I18n",
    Carousel: "Carrossel",
    Clipboard: "Prancheta",
    ContextMenu: "Menu contextual",
    StarRatings: "Classificações por estrelas",
    Autocomplete: "autocompletar",
    Tree: "Árvore",
    // "Import/Export": 'Importar/Exportar',
    Import: "Importar",
    Export: "Exportar",
    ExportSelected: "Exportar Selecionado",
    Others: "Outras",
    MenuLevels: "Níveis de Menu",
    MenuLevel2p1: "Nível de menu 2.1",
    MenuLevel2p2: "Nível de menu 2.2",
    MenuLevel3p1: "Nível de menu 3.1",
    MenuLevel3p2: "Nível de menu 3.2",
    DisabledMenu: "Menu desativado",
    Support: "Apoio, suporte",
    Documentation: "Documentação",
    RaiseSupport: "Levantar Suporte",
    demoTitle: "Título do cartão",
    demoText: `O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.`
  }
}
