// axios
import axios from "axios";

const domain = "https://api.daaweny.com/";
//const domain = "http://38.242.227.244:8082/";

//const domain = "http://localhost:58870/";

if (localStorage.getItem("AccessToken") != null) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("AccessToken");
}

export default axios.create({
  baseURL: domain,
  // You can add your headers here
});
