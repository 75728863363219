import VueCompositionAPI from '@vue/composition-api'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import Vue from 'vue'

import App from './App.vue'
import i18n from './i18n/i18n'
import router from './router'
import store from './store'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/clipboard'
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'
import '@/libs/tour'
import '@/libs/vue-select'
import '@axios'

// Axios Mock Adapter
import '@/@fake-db/db'
import vuesax from "vuesax"
import "vuesax/dist/vuesax.css"
// ACL
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import vSelect from "vue-select"
import acl from "./acl/acl"
// axios
import axios from "./axios.js"
Vue.prototype.$http = axios;
// API Calls
import VCalendar from 'v-calendar'

import rate from "@/components/rate.vue"
import "echarts/lib/chart/bar"
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import "./http/requests"
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.component("rate",rate);
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VCalendar)
// Composition API
Vue.use(VueCompositionAPI)

Vue.use(vuesax);

// Register components in your 'main.js'
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.component("v-select", vSelect);
new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App),
}).$mount('#app')
