import Vue from "vue";
import { AclCreate, AclInstaller, AclRule } from "vue-acl";
import router from "./../router/index";

Vue.use(AclInstaller);

let initialRole = "anonymous";
let userInfo = JSON.parse(localStorage.getItem("userInfo"));
if (userInfo && userInfo.userRole) initialRole = userInfo.userRole;

export default new AclCreate({
  initial: initialRole,
  notfound: "/login",
  router,
  acceptLocalRules: true,
  globalRules: {
    notAuthenticated: new AclRule("anonymous").generate(),
    admin: new AclRule("admin").or("anonymous").generate(),
    editor: new AclRule("editor").or("anonymous").or("admin").generate(),
    all: new AclRule("admin").or("anonymous").generate(),
    anonymous: new AclRule("anonymous").or("patient").or("admin").or("editor").or("doctor").or("hospital").or("hospitalgroup"),
    patient: new AclRule("patient").or("editor"),
    patientanonymous: new AclRule("patient").or("anonymous"),
    doctor: new AclRule("doctor").or("editor"),
    hospital: new AclRule("hospital").or("editor"),
    hospitalgroup: new AclRule("hospitalgroup").or("editor"),
    hospitaldoctor: new AclRule("doctor").or("hospital").or("hospitalgroup").generate(),
  }
});
