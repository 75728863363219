 import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions"
//import getters from "./getters"
import mutations from "./mutations"
import state from "./state"
// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'

 import moduleAuth from './auth/moduleAuth.js'
import verticalMenu from './vertical-menu'
Vue.use(Vuex)

export default new Vuex.Store({
  mutations,
  state,
  actions,
  modules: {
    app,

     auth:moduleAuth,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
