export default [
    {
        path: "/",
        name: "home",
        component: () =>
            import("@/views/HomeV3.vue"),
        meta: {
            rule: "patientanonymous",
        }
    },
    {
        path: "/CollaborateDoctor",
        name: "CollaborateDoctor",
        component: () =>
            import("@/views/CollaborateDoctor.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "CollaborateDoctor", active: true }
            ],
            pageTitle: "CollaborateDoctor",
            rule: "anonymous"
        }
    },
    {
        path: "/CollaborateHospital",
        name: "CollaborateHospital",
        component: () =>
            import("@/views/CollaborateHospital.vue"),
        meta: {
            breadcrumb: [

            ],
            pageTitle: "CollaborateHospital",
            rule: "anonymous"
        }
    },
    {
        path: "/CompletedRegisterationData/:ID?",
        name: "CompletedRegisterationData",
        component: () =>
            import("@/views/patientProfile/CompletedRegisterationData.vue"),
        meta: {
            layout: 'full',
            pageTitle: "CompletedRegisterationData",
            rule: "anonymous"
        }
    },

    {
        path: "/Patient/Home",
        name: "LandingPage",
        component: () =>
            import("@/views/Patient/Home.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                // { title: "Landing Page", active: true }
            ],
            pageTitle: "LandingPage",
            rule: "patient"
        }
    },
    {
        path: "/Patient/PatientMedicalHistory/:ID?",
        name: "PatientMedicalHistory",
        component: () =>
            import("@/views/Patient/PatientMedicalHistory.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PatientMedicalHistory", active: true }
            ],
            pageTitle: "Patient Medical History",
            rule: "patient"
        }
    },

    {
        path: "/PatientMedicalReport",
        name: "PatientMedicalReport",
        component: () =>
            import("@/views/Patient/PatientMedicalReport.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PatientMedicalReports", active: true }
            ],
            pageTitle: "PatientMedicalReports",
            rule: "patient"
        }
    },
    {
        path: "/pendingMedicalReport/:ID?",
        name: "pendingMedicalReport",
        component: () =>
            import("@/views/patientService/pendingMedicalReportTabs.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                // { title: "PendingMedicalReports", active: true }
            ],
            pageTitle: "PendingMedicalReports",
            rule: "patient"
        }
    },
    {
        path: "/patientServices",
        name: "patientServices",
        component: () =>
            import("@/views/patientService/patientService.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Patients' Services", active: true }
            ],
            pageTitle: "ServicesProvidedToOurPatients",
            rule: "anonymous"
        }
    },
    {
        path: "/PatientPaymentPending/",
        name: "PatientPaymentPending",
        component: () =>
            import("@/views/Patient/Payment/PaymentTabs.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PatientPaymentPending", active: true }
            ],
            pageTitle: "PatientPaymentPending",
            rule: "patient"
        }
    },
    {
        path: "/Patient/PatientSurgeries&Treatments/PatientSurgeriesTabs/",
        name: "PatientSurgeries",
        component: () =>
            import(
                "@/views/Patient/PatientSurgeries&Treatments/PatientSurgeriesTabs.vue"
            ),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PatientSurgeries", active: true }
            ],
            pageTitle: "Patient Surgeries",
            rule: "patient"
        }
    },
    {

        path: "/travelArrangments",
        name: "travelArrangments",
        component: () =>
            import("@/views/patientService/travelArrangment.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Travel arrangements", active: true }
            ],
            pageTitle: "ServicesProvidedToOurPatients",
            rule: "anonymous"
        }
    },
    {
        path: "/package/DetailPackage/:ID?",
        name: "DetailPackage",
        component: () =>
            import("@/views/package/DetailPackage.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "DetailPackage", active: true }
            ],
            pageTitle: "DetailPackage",
            rule: "anonymous"
        }
    },


    {
        path: "/OurPackages/:ID?",
        name: "OurPackages",
        component: () =>
            import("@/views/Patient/Packages/OurPackages.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Our Packages", active: true }
            ],
            pageTitle: "OurPackages",
            rule: "anonymous"
        }
    },
    {
        path: "/Patient/Surgeryprofile/:ID?/:StatusID?",
        name: "Surgeryprofile",
        component: () =>
            import(
                "@/views/Patient/PatientSurgeries&Treatments/Surgeryprofile.vue"
            ),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Surgeryprofile", active: true }
            ],
            pageTitle: "Surgeryprofile",
            rule: "patient"
        }
    },
    {
        path: "/Patient/Packages",
        name: "Packages",
        component: () =>
            import(
                "@/views/Patient/Packages/packages.vue"
            ),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Packages", active: true }
            ],
            pageTitle: "Packages",
            rule: "patient"
        }
    },
    {
        path: "/ReservedPackageDetail/:PackageID?",
        name: "ReservedPackageDetail",
        component: () =>
            import("@/views/Patient/Packages/ReservedPackageDetail.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Reserved Package Detail", active: true }
            ],
            pageTitle: "Reserved Package Detail",
            rule: "anonymous"
        }
    },
    {
        path: "/PatientReservationSession/",
        name: "PatientReservationSession",
        component: () =>
            import("@/views/Patient/Reservation/ResevationTabs.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "ReservationSession", active: true }
            ],
            pageTitle: "Reservation Session",
            rule: "patient"
        }
    },
    {
        path: "/Patient/ReservationSessionPrescriptions/:ID?",
        name: "ReservationSessionPrescriptions",
        component: () =>
            import(
                "@/views/Patient/Reservation/ReservationSessionPrescriptions.vue"
            ),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "ReservationSessionPrescriptions", active: true }
            ],
            pageTitle: "Prescriptions",
            rule: "patient"
        }
    },
    {
        path: "/Patient/PrescriptionsList",
        name: "PrescriptionsList",
        component: () =>
            import("@/views/Patient/Reservation/PrescriptionsList.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PrescriptionsList", active: true }
            ],
            pageTitle: "Prescriptions",
            rule: "patient"
        }
    },
    {
        path: "/Patient/PrescriptionPDF/:ID?",
        name: "PrescriptionPDF",
        component: () =>
            import("@/views/Patient/Reservation/PrescriptionPDF.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PrescriptionPDF", active: true }
            ],
            pageTitle: "Prescription",
            rule: "patient"
        }
    },
    {
        path: "/Patient/PatientRepeatMedical/",
        name: "PatientRepeatMedical",
        component: () =>
            import("@/views/Patient/PatientRepeatMedical.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PatientRepeatMedical", active: true }
            ],
            pageTitle: "PatientRepeatMedical",
            rule: "patient"
        }
    },

    {
        path: "/hospitalInfo/:Id?",
        name: "hospitalInfo",
        component: () =>
            import("@/views/hospital/hospitalInfo/hospitalInfo.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "HospitalInfo", active: true }
            ],
            pageTitle: "HospitalInfo",
            rule: "anonymous"
        }
    },
    // {
    //     path: "/AppointmentSearch/:DoctorID?/:CountryID?/:SpecialtyID?",
    //     name: "AppointmentSearch",
    //     component: () =>
    //         import ("@/views/doctor/search/DoctorSearch.vue"),
    //     meta: {
    //         breadcrumb: [
    //             { title: "Home", url: "/" },
    //         ],
    //         pageTitle: "DoctorAndOnlineConsultation",
    //         rule: "anonymous"
    //     }
    // },
    {
        path: "/AppointmentSearch/:DoctorID?/:CountryID?/:SpecialtyID?",
        name: "AppointmentSearch",
        component: () =>
            import("@/views/appointment/appointmentSearch/appointmentSearch.vue"),
        meta: {
            contentClass: 'ecommerce-application',
            breadcrumb: [
                { title: "Home", url: "/" },
            ],
            pageTitle: "DoctorAndOnlineConsultation",
            rule: "anonymous"
        }
    },

    {
        path: "/AppointmentSearchFiltter/:IsHelper?/:IsGeneral?/:IsOnline?",
        name: "AppointmentSearchFiltter",
        component: () =>
            import("@/views/appointment/appointmentSearch/AppointmentSearchFiltter.vue"),
        meta: {
            contentClass: 'ecommerce-application',
            breadcrumb: [
                { title: "Home", url: "/" },
            ],
            pageTitle: "DoctorAndOnlineConsultation",
            rule: "anonymous"
        }
    },



    {

        path: "/DoctorProfile/:ID?/:FromSurgery?",
        name: "DoctorProfile",
        component: () =>
            import("@/views/pages/Profiles/DoctorProfile.vue"),
        meta: {
            rule: "anonymous",
            pageTitle: "Doctor Profile"
        }
    },
    {
        path: "/BookingSummary/:Flag?/:ID?",
        name: "BookingSummary",
        component: () =>
            import("@/views/appointment/appointmentSearch/SummaryBooking.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Booking Summary", active: true }
            ],
            pageTitle: "Booking Summary",
            rule: "patient"

        },
    },
    {
        path: "/TreatmentsProfile/:ID?/:ViewTypeID?/:PatientReservationSurgeryID?",
        name: "TreatmentsProfile",
        component: () =>
            import(
                "@/views/Patient/PatientSurgeries&Treatments/TreatmentsProfile.vue"
            ),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "TreatmentsProfile", active: true }
            ],
            pageTitle: "TreatmentsProfile",
            rule: "anonymous"
        }
    },
    {
        path: "/SurgeryAndTreatment/:CountryID?/:SpecialtyID?",
        name: "SurgeryAndTreatment",
        component: () =>
            import("@/views/Patient/Surgery/SurgeryAndTreatment.vue"),

        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Surgery & Treatment", active: true }
            ],
            pageTitle: "SurgeryTreatment",
            rule: "anonymous"
        }
    },
    {

        path: "/HospitalProfile/:ID?",
        name: "HospitalProfile",
        component: () =>
            import("@/views/pages/Profiles/HospitalProfileTabs.vue"),
        meta: {
            rule: "anonymous",
            pageTitle: "HospitalProfile "
        }
    },

    // doctor portal

    {
        path: "/doctor/Home",
        name: "doctorLandingPage",

        component: () =>
            import("@/views/doctor/Home.vue"),
        meta: {

            pageTitle: "LandingPage",
            rule: "doctor"
        }
    },
    {
        path: "/YourPatient/:ID?",
        name: "YourPatient",
        component: () =>
            import("@/views/doctorProfile/DoctorPatient.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/doctor/Home" },
            ],
            pageTitle: "YourPatient",
            rule: "doctor"
        }
    },
    {
        path: "/onlineAppointment/:ID?",
        name: "onlineAppointment",
        component: () =>
            import("@/views/doctorProfile/onlineAppointment.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/doctor/Home" },
            ],
            pageTitle: "OnlineAppointment",
            rule: "doctor"
        }
    },
    {
        path: "/DoctorAddAvailability",
        name: "DoctorAddAvailability",
        component: () =>
            import("@/views/doctor/Appointment/DoctorAddAvailability.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/doctor/Home" },
            ],
            pageTitle: "AddAvailability",
            rule: "doctor"
        }
    },
    {
        path: "/DoctorEditAvailability",
        name: "DoctorEditAvailability",
        component: () =>
            import("@/views/doctor/Appointment/DoctorEditAvailability.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/doctor/Home" },
            ],
            pageTitle: "EditAvailability",
            rule: "doctor"
        }
    },
    {
        path: "/DoctorYearSummaryAppointments",
        name: "DoctorYearSummaryAppointments",
        component: () =>
            import("@/views/doctor/Appointment/DoctorYearSummaryAppointments.vue"),
        meta: {
            pageTitle: "DoctorYearSummaryAppointments",
            rule: "doctor"
        }
    },
    {
        path: "/pendingTaskDoctor/:ID?",
        name: "pendingTaskDoctor",
        component: () =>
            import("@/views/doctorProfile/pendingTaskDoctor.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/doctor/Home" },
            ],
            pageTitle: "PendingTaskDoctor",
            rule: "doctor"
        }
    },
    {
        path: "/hospital/ReservedSurgery",
        name: "ReservedSurgery",
        component: () =>
            import("@/views/hospital/ReservedSurgery.vue"),
        meta: {

            pageTitle: "ReservedSurgery",
            rule: "hospitaldoctor"
        }
    },
    {
        path: "/DoctorWeeklyAppointments",
        name: "DoctorWeeklyAppointments",
        component: () =>
            import("@/views/doctor/Appointment/DoctorWeeklyAppointments.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/doctor/Home" },
            ],
            pageTitle: "DoctorWeeklyAppointments",
            rule: "doctor"
        }
    },
    {
        path: "/doctor/DoctorSessionDetails/:PatientReservationID?/:IsDoctorRequest?",
        name: "DoctorSessionDetails",
        component: () =>
            import("@/views/doctor/Appointment/DoctorSessionDetails.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/DoctorWeeklyAppointments" },
            ],
            pageTitle: "DoctorSessionDetails",
            rule: "doctor"
        }
    },
    {
        path: "/PatientMedicalHistorySession/:ID?",
        name: "PatientMedicalHistorySession",
        component: () =>
            import("@/views/doctor/Patients/PatientMedicalHistory.vue"),
        meta: {

            pageTitle: "PatientMedicalHistory",
            rule: "hospitaldoctor"
        }
    },
    {
        path: "/PatientReservation/:DoctorID?/:PatientID?",
        name: "PatientReservation",
        component: () =>
            import("@/views/doctorProfile/PatientReservation.vue"),
        meta: {
            pageTitle: "PatientHistory",
            rule: "doctor"
        }
    },

    {
        path: "/PatientDiagnosisPrescription/:patientReservationSessionID?/:patientID?",
        name: "PatientDiagnosisPrescription",
        component: () =>
            import(
                "@/views/doctor/Patients/PatientDiagnosisAndPrescription.vue"
            ),
        meta: {

            pageTitle: "PatientDiagnosisPrescription",
            rule: "doctor"
        }
    },

    {
        path: "/Patient/PatientSetting",
        name: "PatientSetting",
        component: () =>
            import("@/views/Patient/PatientSetting.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "PatientSetting", active: true }
            ],
            pageTitle: "PatientSetting",
            rule: "patient"
        }
    },

    {
        path: "/doctor/DoctorSetting",
        name: "DoctorSetting",
        component: () =>
            import("@/views/doctor/DoctorSetting.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Setting", active: true }
            ],
            pageTitle: "Setting",
            rule: "doctor"
        }
    },
    {
        path: "/DoctorProfileData",
        name: "DoctorProfileData",
        component: () =>
            import("@/views/doctorProfile/DoctorProfileData.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/doctor/home" },
            ],
            pageTitle: "DoctorProfileData",
            rule: "doctor"
        }
    },
    {
        path: "/CompletedRegisterationDoctorData/:ID?",
        name: "CompletedRegisterationDoctorData",
        component: () =>
            import(
                "@/views/doctorProfile/CompletedRegisterationDoctorData.vue"
            ),
        meta: {
            layout: 'full',
            pageTitle: "CompletedRegisterationData",
            rule: "anonymous"
        }
    },

    {
        path: "/ChangePassword",
        name: "ChangePassword",
        component: () =>
            import("@/views/changePassword.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/" },
                { title: "ChangePassword", active: true }
            ],
            pageTitle: "Change Password",
        }
    },

    {
        path: "/hospital/Home",
        name: "hospitalLandingPage",
        component: () =>
            import("@/views/hospital/Home.vue"),
        meta: {
            pageTitle: "LandingPage",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/Specialty",
        name: "hospitalSpecialty",
        component: () =>
            import("@/views/hospital/viewHospitalSpecialty.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/hospital/Home" },
            ],
            pageTitle: "HospitalSpecialty",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/AddHospitalSpecialites",
        name: "AddHospitalSpecialites",
        component: () =>
            import("@/views/hospital/AddHospitalSpecialites.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/hospital/Home" },
            ],
            pageTitle: "AddHospitalSpecialities",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/AddHospitalSubSpecialites",
        name: "AddHospitalSubSpecialites",
        component: () =>
            import("@/views/hospital/AddHospitalSubSpecialites.vue"),
        meta: {
            breadcrumb: [
                { title: "AddHospitalSpecialites", url: "/hospital/AddHospitalSubSpecialites" },
            ],
            pageTitle: "AddHospitalSubSpecialites",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/AddHospitalDoctors",
        name: "AddHospitalDoctors",
        component: () =>
            import("@/views/hospital/AddHospitalDoctors.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/hospital/Home" },
            ],
            pageTitle: "AddHospitalDoctors",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/Doctor",
        name: "hospitalDoctor",
        component: () =>
            import("@/views/hospital/ViewHospitalDoctor.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/hospital/Home" },
            ],
            pageTitle: "HospitalDoctor",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/HospitalSetting",
        name: "HospitalSetting",
        component: () =>
            import("@/views/hospital/HospitalSetting.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/hospital/Home" },
            ],
            pageTitle: "HospitalSetting",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/payments",
        name: "HospitalPayments",
        component: () =>
            import("@/views/hospital/payments.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/hospital/Home" },
            ],
            pageTitle: "HospitalPayments",
            rule: "hospital"
        }
    },
    {
        path: "/CompletedRegisterationHospital/:Id?",
        name: "CompletedRegisterationHospital",
        component: () =>
            import("@/views/hospital/hospitaldetails.vue"),
        meta: {
            layout: 'full',
            pageTitle: "hospital",
            rule: "anonymous"
        }
    },
    {
        path: "/UpdateHospitalProfile/:Id?",
        name: "UpdateHospitalProfile",
        component: () =>
            import("@/views/hospital/hospitaldetails.vue"),
        meta: {
            breadcrumb: [
                { title: "Home", url: "/hospital/Home" },
            ],
            pageTitle: "hospital",
            rule: "anonymous"
        }
    },
    {
        path: "/hospital/Add-EditTreatmentSurgery/:HospitalSurgeryID?",
        name: "AddEditTreatmentSurgery",
        component: () =>
            import("@/views/hospital/AddEditTreatmentSurgery.vue"),
        meta: {
            pageTitle: "AddTreatmentSurgery",
            rule: "hospital"
        }
    },
    {
        path: "/hospital/AddTreatmentSurgery",
        name: "AddTreatmentSurgery",
        component: () =>
            import("@/views/hospital/AddTreatment.vue"),
        meta: {

            pageTitle: "AddTreatmentSurgery",
            rule: "hospital"
        }
    },
    {
        path: "/TermsAndCondetions",
        name: "PrivacyPolicy",
        component: () =>
            import("@/views/TermsAndCondetionsPopup.vue"),
        meta: {
            breadcrumb: [

            ],
            pageTitle: "TermsAndCondetions",
            rule: "anonymous"
        }
    },
    // {
    //     path: "/TermsAndCondetionsAr",
    //     name: "TermsAndCondetionsAr",
    //     component: () =>
    //         import ("@/views/TermsAndCondetionsPopupAr.vue"),
    //     meta: {
    //         breadcrumb: [

    //         ],
    //         pageTitle: "TermsAndCondetions",
    //         rule: "anonymous"
    //     }
    // },

]


// export default [
//   {
//     path: "/",
//     name: "home",
//     component: () =>
//         import ("@/views/HomeV3.vue"),
//     meta: {
//         rule: "anonymous",
//         pageTitle: "Home"
//             /// authRequired: true
//     }
// },

// // =============================================================================
// // Pages Routes
// // =============================================================================

// {
//     path: "/Patient/ReservationSession/:ID?",
//     name: "ReservationSession",
//     component: () =>
//         import ("@/views/Patient/Reservation/ReservationSession.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "ReservationSession", active: true }
//         ],
//         pageTitle: "Reservation Session",
//         rule: "patient"
//     }
// },

// {
//     path: "/Patient/InComingSurgeries/:ID?",
//     name: "InComingSurgeries",
//     component: () =>
//         import (
//             "@/views/Patient/PatientSurgeries&Treatments/InComingSurgeries.vue"
//         ),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "InComingSurgeries", active: true }
//         ],
//         pageTitle: "Surgeries",
//         rule: "patient"
//     }
// },
// {

// {
//     path: "/Patient/OldSurgeries/:ID?",
//     name: "OldSurgeries",
//     component: () =>
//         import (
//             "@/views/Patient/PatientSurgeries&Treatments/OldSurgeries.vue"
//         ),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "OldSurgeries", active: true }
//         ],
//         pageTitle: "Surgeries",
//         rule: "patient"
//     }
// },




// // end Pending medical AddHospitalSpecialites
// // start Patient Medical History Session mkl

// // end Patient Medical Session History


// {
//     path: "/DoctorRate/:DoctorID?/:PatientReserviedSessionID?",
//     name: "DoctorRate",
//     component: () =>
//         import ("@/views/doctor/DoctorRate.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Doctor' Rate", active: true }
//         ],
//         pageTitle: "Doctor' Rate",
//         rule: "patient"
//     }
// },
// {
//     path: "/HospitalRate/:HospitalID?/:PatientReseriedSurgeryID?",
//     name: "DoctorRate",
//     component: () =>
//         import ("@/views/hospital/HospitalRate.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Doctor' Rate", active: true }
//         ],
//         pageTitle: "Doctor' Rate",
//         rule: "patient"
//     }
// },



// {
//     path: "/TravelarrangementsDialog",
//     name: "TravelarrangementsDialog",
//     component: () =>
//         import ("@/views/patientService/TravelarrangementsDialog.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Travel arrangements' Dialog", active: true }
//         ],
//         pageTitle: "Travel arrangements' Dialog",
//         rule: "anonymous"
//     }
// },

// {
//     path: "/PaymentAlertDialog",
//     name: "PaymentAlertDialog",
//     component: () =>
//         import ("@/views/Patient/PaymentAlertDialog.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Payment Alert ' Dialog", active: true }
//         ],
//         pageTitle: "Payment Alert ' Dialog",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/ExtraService",
//     name: "ExtraService",
//     component: () =>
//         import ("@/views/patientService/ExtraService.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Extra Service", active: true }
//         ],
//         pageTitle: "Extra Service",
//         rule: "anonymous"
//     }
// },

// //payment part

// // {
// //   path: "/ConfirmPayment",
// //   name: "ConfirmPayment",
// //   component: () => import("@/views/Payment/ConfirmPayment.vue"),
// //   meta: {
// //     breadcrumb: [
// //       { title: "Home", url: "/" },
// //       { title: "Confirm Payment", active: true }
// //     ],
// //     pageTitle: "Confirm Payment",
// //     rule: "anonymous"
// //   }
// // },

// {
//     path: "/Extras",
//     name: "Extras",
//     component: () =>
//         import ("@/views/Patient/PatientSurgeries&Treatments/Extras.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Travel arrangements' Dialog", active: true }
//         ],
//         pageTitle: "Travel arrangements' Dialog",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/Travelarrangements",
//     name: "Travelarrangements",
//     component: () =>
//         import (
//             "@/views/Patient/PatientSurgeries&Treatments/Travelarrangements.vue"
//         ),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Travel arrangements' Dialog", active: true }
//         ],
//         pageTitle: "Travel arrangements' Dialog",
//         rule: "anonymous"
//     }
// },



// {
//     path: "/homePatientt",
//     name: "homePatient",
//     component: () =>
//         import ("@/views/patientProfile/homePatientt.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Travel Arrangments", active: true }
//         ],
//         pageTitle: "Travel Arrangments",
//         rule: "anonymous"
//     }
// },






// {


// //  end patientProfile


// // //doctorProfile
// // {
// //   path: "/doctor/MInInfoo",

// //   component: () => import("@/views/doctorProfile/MInInfoo.vue"),
// //   meta: {
// //     breadcrumb: [
// //       { title: "Home", url: "/" },
// //       { title: "Landing Page", active: true }
// //     ],
// //     pageTitle: "Landing Page",
// //     rule: "anonymous"
// //   }
// // },

// //doctorProfile

// {
//     path: "/medicalHistory",
//     name: "medicalHistory",
//     component: () =>
//         import ("@/views/doctorProfile/medicalHistory.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Travel Arrangments", active: true }
//         ],
//         pageTitle: "Travel Arrangments",
//         rule: "anonymous"
//     }
// },

// {
//     path: "/PatientMedicalHistory/:ID?",

//     name: "PatientMedicalHistory",
//     component: () =>
//         import ("@/views/doctor/Patients/PatientMedicalHistory.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Patient Medical History", active: true }
//         ],
//         pageTitle: "Patient Medical History",
//         rule: "hospitaldoctor"
//     }
// },


// {
//     path: "/PatientSymptomsDescription/:PatientReservationID?",
//     name: "PatientsSymptomsDescription",
//     component: () =>
//         import ("@/views/doctor/Patients/PatientsSymptomsDescription.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Patient's Symptoms Description", active: true }
//         ],
//         pageTitle: "Patient's Symptoms Description",
//         rule: "doctor"
//     }
// },
// {
//     path: "/AddPatientSymptomsDescription/:PatientReservationID?",

//     name: "AddPatientSymptomsDescription",
//     component: () =>
//         import ("@/views/doctor/Patients/AddPatientSymptomsDescription.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Patient's Symptoms Description", active: true }
//         ],
//         pageTitle: "Patient's Symptoms Description",
//         rule: "doctor"
//     }
// },



// // end doctorProfile

// // start doctor patient reservation mkl



// // end doctor patient reservation
// // start Pending medical Reports Download documentations mkl
// // {
// //  path: "/pendingMedicalReportsDownload/:patientID?/:DoctorID?",
// // name: "pendingMedicalReportsDownload",
// // component: () => import("@/views/doctorProfile/pendingMedicalReportsDownload.vue"),
// // meta: {
// //  breadcrumb: [
// //    { title: "Home", url: "/" },
// //    { title: "pending Medical Reports Download", active: true }
// //  ],
// //  pageTitle: "pending Medical Reports Download",
// //  rule: "anonymous"
// // }
// // },
// // end Pending medical Reports Download documentations

// // start doctor online Appointment mkl


// // end doctor pending Task
// {
//     path: "/DoctorBlockAlert",
//     name: "DoctorBlockAlert",
//     component: () =>
//         import ("@/views/doctor/manage/DoctorBlockAlert.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: " Block Alert", active: true }
//         ],
//         pageTitle: "Block Alertn",
//         rule: "anonymous"
//     }
// },
// // end doctor patient reservation





// {
//     path: "/hospital/AddTreatmentSurgery",
//     name: "AddTreatmentSurgery",
//     component: () =>
//         import ("@/views/hospital/AddTreatment.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Add Treatment Surgery", active: true }
//         ],
//         pageTitle: "Add Treatment Surgery",
//         rule: "hospital"
//     }
// },


// {
//     path: "/hospital/AddHospitalSurgeryDoctors",
//     name: "AddHospitalSurgeryDoctors",
//     component: () =>
//         import ("@/views/hospital/AddHospitalSurgeryDoctors.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Add Hospital Surgery Doctors", active: true }
//         ],
//         pageTitle: "Add Hospital Surgery Doctors",
//         rule: "hospital"
//     }
// },

// {
//     path: "/hospital/HospitalSetting",
//     name: "HospitalSetting",
//     component: () =>
//         import ("@/views/hospital/HospitalSetting.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Hospital Setting", active: true }
//         ],
//         pageTitle: "Hospital Setting",
//         rule: "hospital"
//     }
// },
//  {
//     path: "/hospitalInfo",
//         name: "hospitalInfo",
//             component: () =>
//                 import("@/views/hospital/hospitalInfo.vue"),
//                 meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Travel Arrangments", active: true }
//         ],
//             pageTitle: "Travel Arrangments",
//                 rule: "anonymous"
//     }
// },

// {
//     path: "/CreateDoctorProfile",
//     name: "CreateDoctorProfile",
//     component: () =>
//         import ("@/views/hospital/CreateDoctorProfile.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Create Doctor Profile ", active: true }
//         ],
//         pageTitle: "Create Doctor Profile",
//         rule: "anonymous"
//     }
// },

// {
//     path: "/hospitaldetails",
//     name: "hospitaldetails",
//     component: () =>
//         import ("@/views/hospital/hospitaldetails.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "hospital", active: true }
//         ],
//         pageTitle: "hospital",
//         rule: "anonymous"
//     }
// },

// //end hospital
// {
//     path: "/DoctorSearch/:CountryID?/:SpecialtyID?",
//     name: "DoctorSearch",
//     component: () =>
//         import ("@/views/doctor/search/DoctorSearch.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Doctor Search", active: true }
//         ],
//         pageTitle: "DoctorAndOnlineConsultation",
//         rule: "anonymous"
//     }
// },





// {
//     path: "/PackageDetail/:PackageID?",
//     name: "PackageDetail",
//     component: () =>
//         import ("@/views/package/PackageDetail.vue"),
//     meta: {
//         breadcrumb: [
//             { title: "Home", url: "/" },
//             { title: "Package Detail", active: true }
//         ],
//         pageTitle: "Package Detail",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/FAQ",
//     name: "FAQ",
//     component: () =>
//         import ("@/views/FAQPopup.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "FAQ",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/AboutUs",
//     name: "AboutUs",
//     component: () =>
//         import ("@/views/AboutUs.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "AboutUs",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/CollaborateDoctor",
//     name: "CollaborateDoctor",
//     component: () =>
//         import ("@/views/CollaborateDoctor.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "CollaborateDoctor",
//         rule: "anonymous"
//     }
// },

// {
//     path: "/CollaborateJoinOurTeam",
//     name: "CollaborateJoinOurTeam",
//     component: () =>
//         import ("@/views/CollaborateJoinOurTeam.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "CollaborateJoinOurTeam",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/CollaborateStrategic",
//     name: "CollaborateStrategic",
//     component: () =>
//         import ("@/views/CollaborateStrategic.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "CollaborateStrategic",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/Collaborate",
//     name: "Collaborate",
//     component: () =>
//         import ("@/views/Collaborate.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "Collaborate",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/Ar/FAQ",
//     name: "FAQ",
//     component: () =>
//         import ("@/views/FAQPopupAr.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "FAQ",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/TermsAndCondetions",
//     name: "TermsAndCondetions",
//     component: () =>
//         import ("@/views/TermsAndCondetionsPopup.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "TermsAndCondetions",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/TermsAndCondetionsAr",
//     name: "TermsAndCondetionsAr",
//     component: () =>
//         import ("@/views/TermsAndCondetionsPopupAr.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "TermsAndCondetions",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/PatientWaiver",
//     name: "PatientWaiver",
//     component: () =>
//         import ("@/views/PatientWaiverPopup.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "PatientWaiver",
//         rule: "anonymous"
//     }
// },
// {
//     path: "/Ar/PatientWaiver",
//     name: "ArPatientWaiver",
//     component: () =>
//         import ("@/views/PatientWaiverPopupAr.vue"),
//     meta: {
//         breadcrumb: [

//         ],
//         pageTitle: "PatientWaiver",
//         rule: "anonymous"
//     }
// }
// ]
